import { faker } from '@faker-js/faker/locale/en'
import { DateTime } from 'luxon'
import type {
  ContainerType,
  ContainerWatchState,
  GETWatch,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'
import { shortenContainerType } from '~/utils'

export class ContainerWatch {
  container_number: string
  state: ContainerWatchState
  shipping_line: ShippingLine | null
  container_type: ContainerType | null
  type_short: string | undefined
  customer_id: number | null
  customer_name: string | null
  terminal: TerminalName | null
  import_last_free_date: DateTime | null
  tags: string[] = []
  import_appointment_pickup_number: string | null
  tagsSet: Set<string> = new Set()
  constructor(watch: GETWatch, demoMode: boolean) {
    this.container_number = watch.container_number
    this.state = watch.state
    this.shipping_line = watch.shipping_line ?? null
    this.container_type = watch.container_type ?? null
    this.customer_id = null
    this.customer_name = null
    this.type_short = shortenContainerType(this.container_type || undefined)
    this.import_appointment_pickup_number =
      watch.import_appointment_pickup_number ?? null
    this.terminal = watch.last_related_terminal ?? null
    this.import_last_free_date = watch.import_last_free_date
      ? DateTime.fromISO(watch.import_last_free_date)
      : null
    if (watch.customer) {
      this.customer_id = watch.customer.id
      if (demoMode) {
        this.customer_name = faker.company.name()
      } else {
        this.customer_name = watch.customer.name
      }
    }
    this.tags = Array.isArray(watch.tags) ? watch.tags : []
    this.tagsSet = new Set(this.tags)
  }

  get type(): ContainerType | undefined {
    return this.container_type || undefined // Alias
  }

  get line(): ShippingLine | undefined {
    return this.shipping_line || undefined // Alias
  }
}
