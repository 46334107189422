<script lang="ts" setup>
import { ElAlert } from 'element-plus'
import CredentialsInput from '../inputs/CredentialsInput.vue'
import LbctApiConnectionStatus from './LbctApiConnectionStatus.vue'
import Help from '~/components/display/Help.vue'
import {
  EDITABLE_CONNECTION_TYPES,
  UAS_EDITABLE_CONNECTION_TYPES,
} from '~/constants'
import { useConnectionsStore } from '~/stores/connections'
const props = withDefaults(
  defineProps<{
    uasConnectionsOnly: boolean
    showDirections?: boolean
  }>(),
  {
    showDirections: true,
  }
)

const connectionsStore = useConnectionsStore()
onMounted(() => {
  connectionsStore.loadConnectionsIfNotLoaded()
})

const connectionTypes = computed(() => {
  if (props.uasConnectionsOnly) {
    return UAS_EDITABLE_CONNECTION_TYPES
  }
  return EDITABLE_CONNECTION_TYPES
})

const alertDesc = computed(() => {
  if (props.uasConnectionsOnly) {
    return (
      'To use the UAS, you must have valid login credentials for the following ' +
      'accounts.'
    )
  }
  return (
    'To use Dray Dog, you must have valid login credentials for the following ' +
    'accounts.'
  )
})
</script>

<template>
  <div v-if="props.showDirections" class="mb-4 max-w-800px">
    <ElAlert title="Directions" type="info" show-icon :closable="false">
      {{ alertDesc }}
    </ElAlert>
  </div>
  <div v-loading="connectionsStore.loading">
    <div
      v-for="connectionMeta in connectionTypes"
      :key="connectionMeta.type"
      class="flex items-center my-2"
    >
      <a
        :href="connectionMeta.url"
        target="blank"
        rel="noreferrer"
        class="portal-link"
      >
        <span class="underline">{{ connectionMeta.label }}</span>
        <i-mdi:open-in-new class="align-text-bottom ml-1" />
      </a>

      <CredentialsInput :connection-meta="connectionMeta" />
      <div v-if="connectionMeta.note">
        <Help
          v-if="connectionMeta.note"
          :title="connectionMeta.label"
          class="ml-1"
          >{{ connectionMeta.note }}</Help
        >
      </div>
    </div>
    <div
      v-if="props.uasConnectionsOnly === false"
      v-loading="connectionsStore.loading"
      class="flex items-center my-2"
    >
      <span class="portal-link underline">LBCT API Connection Status:</span>
      <LbctApiConnectionStatus />
    </div>
  </div>
</template>

<style scoped lang="scss">
.portal-link {
  @apply block w-220px pr-5px mr-2 flex items-center justify-end;
}
</style>
