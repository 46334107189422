import { defineStore } from 'pinia'
import { useUserStore } from './user'
import BookingRequest from '~/models/bookingRequest'
import type { ActionType } from '~/services/apiClient'
import { AppointmentBookingApi } from '~/services/apiClient'

export const useBookingRequestsStore = defineStore('bookingRequests', () => {
  const bookingRequests = ref<BookingRequest[]>([])
  const loading = ref(false)
  const abortController = ref<AbortController | null>(null)

  function load({
    containerSearchText,
    selectedUserIDs,
  }: {
    containerSearchText?: string
    selectedUserIDs?: Array<number>
  }) {
    loading.value = true
    bookingRequests.value = []

    if (abortController.value) {
      abortController.value.abort()
    }
    const newAbortController = new AbortController()
    abortController.value = newAbortController

    const userStore = useUserStore()
    const api = new AppointmentBookingApi()
    api
      .getBookingHistoryAppointmentsBookingHistoryGet(
        selectedUserIDs,
        containerSearchText,

        {
          signal: abortController.value.signal,
        }
      )
      .then((resp) => {
        bookingRequests.value = resp.data.map(
          (b) => new BookingRequest(b, userStore.demo_mode)
        )
      })
      .finally(() => {
        if (newAbortController === abortController.value) {
          loading.value = false
          abortController.value = null
        }
      })
  }

  function markEnteredInTMS({
    entered_in_tms,
    actionRequestID,
    actionType,
  }: {
    entered_in_tms: boolean
    actionRequestID: number
    actionType: 'book' | 'book_empty' | 'reschedule'
  }) {
    const api = new AppointmentBookingApi()
    api.patchBookingHistoryAppointmentsBookingHistoryMutationRequestIdPatch(
      actionRequestID,
      { entered_in_tms },
      actionType
    )
  }

  function enterInTMS({
    actionRequestID,
    actionType,
  }: {
    actionRequestID: number
    actionType: ActionType
  }) {
    const api = new AppointmentBookingApi()
    const promise =
      api.enterActionRequestInTmsAppointmentsBookingHistoryActionRequestIdEnterInTmsPost(
        actionRequestID,
        actionType
      )
    return promise
  }
  return {
    bookingRequests,
    loading,
    load,
    markEnteredInTMS,
    enterInTMS,
  }
})
