<script lang="ts" setup>
import { ElTag } from 'element-plus'
import {
  ConnectionType,
  ConnectionValidationStatus,
} from '~/services/apiClient'
import { useConnectionsStore } from '~/stores/connections'

const connectionsStore = useConnectionsStore()
const lbctConnection = computed(() => {
  return connectionsStore.connectionLookup.get(ConnectionType.LbctApi)
})
const tooltip = computed(() => {
  if (lbctConnection.value) {
    return 'Connection status is ' + lbctConnection.value.validation_status
  }
  return "You don't have an LBCT API connection yet. Rest assured the team has requested one for you from LBCT. We will notify you when it is ready."
})
</script>

<template>
  <div class="flex items-center">
    <!-- <span class="underline">LBCT Connection Status</span> -->
    <div style="width: 440px" class="flex items-right">
      <template v-if="lbctConnection">
        <ElTag
          v-if="
            lbctConnection.validation_status ===
            ConnectionValidationStatus.Validated
          "
          v-tooltip="tooltip"
          type="success"
          ><b>Connected</b>
        </ElTag>
        <ElTag v-else v-tooltip="tooltip" type="danger"
          ><b>Disconnected</b></ElTag
        >
      </template>
      <template v-else
        ><ElTag v-tooltip="tooltip" type="danger"><b>Not Connected</b></ElTag>
      </template>
    </div>
  </div>
</template>
