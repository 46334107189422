<script setup lang="ts" generic="T extends { id: string }">
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import StickyVirtualScrollerRowWrapper from './StickyVirtualScrollerRowWrapper.vue'

// Types
interface ItemWrapper {
  id: string
  item: T
  rowHeight: number
  position: number
  isHeader: boolean
  isSubHeader: boolean
}

// Props & Slots
const props = withDefaults(
  defineProps<{
    items: T[]
    headerHeight: number
    getHeaderId: (item: T) => string
    getSubHeaderId?: (item: T) => string
    getRowHeight: (item: T) => number
    subHeaderHeight?: number
    bufferPixels?: number
  }>(),
  {
    bufferPixels: 200,
  }
)

const emit = defineEmits<{
  (event: 'headerChange', headerid: string | null): void
  (event: 'subHeaderChange', subheaderid: string | null): void
}>()

// Expose methods to parent
defineExpose({
  scrollToHeader,
})

defineSlots<{
  default(props: { item: T }): VNode | Component
  header(props: { item: T }): VNode | Component
  subheader(props: { item: T }): VNode | Component
}>()

// Refs
const recycleScrollerRef = ref<any>()
const headerItemsByHeaderids = ref(new Map<string, T>())
const subHeaderItemsByHeaderids = ref(new Map<string, T>())
const itemWrappers = ref<ItemWrapper[]>([])
const itemWrappersByHeaderID = ref<Map<string, ItemWrapper>>(new Map())

// Visibility tracking
const visibleItemsByHeaderID = ref(new Map<string, Set<string>>())
const visibleItemsBySubHeaderID = ref(new Map<string, Set<string>>())
const visibleHeaderIDs = ref(new Set<string>())
const visibleSubHeaderIDs = ref(new Set<string>())
const topHeaderID = ref<string | null>(null)
const topSubHeaderID = ref<string | null>(null)
const scrollbarWidth = ref(0)

// Computed
const currentHeaderItem = computed<T | undefined>(() => {
  if (!topHeaderID.value) return undefined
  return headerItemsByHeaderids.value.get(topHeaderID.value) as T | undefined
})

const currentSubHeaderItem = computed<T | undefined>(() => {
  if (!topSubHeaderID.value || !props.getSubHeaderId) return undefined
  const item = subHeaderItemsByHeaderids.value.get(topSubHeaderID.value) as
    | T
    | undefined
  if (!item) {
    console.error(`Subheader item not found for id: ${topSubHeaderID.value}`)
  }
  return item
})

// Watchers
watch(
  () => props.items,
  (newItems) => {
    let previousItemHeaderid: string | null = null
    let previousItemSubHeaderid: string | null = null
    const newItemWrappers: ItemWrapper[] = []
    const newHeaderItemsByHeaderids = new Map<string, T>()
    const newSubHeaderItemsByHeaderids = new Map<string, T>()
    const newItemWrappersByHeaderID = new Map<string, ItemWrapper>()
    let position = 0
    for (let i = 0; i < newItems.length; i++) {
      const item = newItems[i]
      const headerid = props.getHeaderId(item)
      const subHeaderID = props.getSubHeaderId?.(item) ?? null
      const isHeader = headerid !== previousItemHeaderid
      let isSubHeader = false
      if (subHeaderID) {
        isSubHeader = subHeaderID !== previousItemSubHeaderid
      }

      let rowHeight = props.getRowHeight(item)

      if (isHeader) {
        newHeaderItemsByHeaderids.set(headerid, item)
        previousItemHeaderid = headerid
        rowHeight += props.headerHeight
      }
      if (isSubHeader && subHeaderID) {
        newSubHeaderItemsByHeaderids.set(subHeaderID, item)
        previousItemSubHeaderid = subHeaderID
        rowHeight += props.subHeaderHeight ?? props.headerHeight
      }
      const itemWrapper: ItemWrapper = {
        id: item.id,
        item,
        rowHeight,
        isHeader,
        isSubHeader,
        position,
      }
      newItemWrappers.push(itemWrapper)
      if (isHeader) {
        newItemWrappersByHeaderID.set(headerid, itemWrapper)
      }
      position += rowHeight
    }
    itemWrappers.value = newItemWrappers
    itemWrappersByHeaderID.value = newItemWrappersByHeaderID
    headerItemsByHeaderids.value = newHeaderItemsByHeaderids
    subHeaderItemsByHeaderids.value = newSubHeaderItemsByHeaderids
  },
  { immediate: true }
)

// Header visibility effects
watchEffect(() => {
  if (visibleHeaderIDs.value.size === 0) {
    if (topHeaderID.value !== null) {
      topHeaderID.value = null
      emit('headerChange', null)
    }
    return
  }

  const newTopHeaderID = Array.from(visibleHeaderIDs.value).reduce(
    (min, curr) => (curr < min ? curr : min)
  )
  if (newTopHeaderID === topHeaderID.value) return

  topHeaderID.value = newTopHeaderID
  emit('headerChange', newTopHeaderID)
})

watchEffect(() => {
  if (!props.getSubHeaderId) return

  if (visibleSubHeaderIDs.value.size === 0) {
    if (topSubHeaderID.value !== null) {
      topSubHeaderID.value = null
      emit('subHeaderChange', null)
    }
    return
  }

  const newTopHeaderID = Array.from(visibleSubHeaderIDs.value).reduce(
    (min, curr) => (curr < min ? curr : min)
  )
  if (newTopHeaderID === topSubHeaderID.value) return

  topSubHeaderID.value = newTopHeaderID
  emit('subHeaderChange', newTopHeaderID)
})

// Event handlers
function onRowVisible(item: T) {
  const headerid = props.getHeaderId(item)
  if (!visibleItemsByHeaderID.value.has(headerid)) {
    visibleItemsByHeaderID.value.set(headerid, new Set())
    visibleHeaderIDs.value.add(headerid)
  }
  visibleItemsByHeaderID.value.get(headerid)!.add(item.id)

  if (props.getSubHeaderId) {
    const subheaderid = props.getSubHeaderId(item)
    if (!visibleItemsBySubHeaderID.value.has(subheaderid)) {
      visibleItemsBySubHeaderID.value.set(subheaderid, new Set())
      visibleSubHeaderIDs.value.add(subheaderid)
    }
    visibleItemsBySubHeaderID.value.get(subheaderid)!.add(item.id)
  }
}

function onRowHidden(item: T, position: 'above' | 'below' | null) {
  const headerid = props.getHeaderId(item)
  const visibleItemIDs = visibleItemsByHeaderID.value.get(headerid)
  if (visibleItemIDs) {
    visibleItemIDs.delete(item.id)
    if (visibleItemIDs.size === 0) {
      visibleItemsByHeaderID.value.delete(headerid)
      visibleHeaderIDs.value.delete(headerid)
    }
  }

  if (props.getSubHeaderId) {
    const subHeaderID = props.getSubHeaderId(item)
    const visibleItemIDs = visibleItemsBySubHeaderID.value.get(subHeaderID)
    if (visibleItemIDs) {
      visibleItemIDs.delete(item.id)
      if (visibleItemIDs.size === 0) {
        visibleItemsBySubHeaderID.value.delete(subHeaderID)
        visibleSubHeaderIDs.value.delete(subHeaderID)
      }
    }
  }

  if (position === 'above') {
    for (const visibleHeaderid of visibleHeaderIDs.value) {
      if (visibleHeaderid < headerid) {
        visibleItemsByHeaderID.value.delete(visibleHeaderid)
        visibleHeaderIDs.value.delete(visibleHeaderid)
      }
    }
    if (props.getSubHeaderId) {
      const subheaderid = props.getSubHeaderId(item)
      for (const visibleSubHeaderid of visibleSubHeaderIDs.value) {
        if (visibleSubHeaderid < subheaderid) {
          visibleItemsBySubHeaderID.value.delete(visibleSubHeaderid)
          visibleSubHeaderIDs.value.delete(visibleSubHeaderid)
        }
      }
    }
  } else if (position === 'below') {
    for (const visibleHeaderid of visibleHeaderIDs.value) {
      if (visibleHeaderid > headerid) {
        visibleItemsByHeaderID.value.delete(visibleHeaderid)
        visibleHeaderIDs.value.delete(visibleHeaderid)
      }
    }
    if (props.getSubHeaderId) {
      const subheaderid = props.getSubHeaderId(item)
      for (const visibleSubHeaderid of visibleSubHeaderIDs.value) {
        if (visibleSubHeaderid > subheaderid) {
          visibleItemsBySubHeaderID.value.delete(visibleSubHeaderid)
          visibleSubHeaderIDs.value.delete(visibleSubHeaderid)
        }
      }
    }
  }
}

function scrollToHeader(headerID: string) {
  if (!recycleScrollerRef.value) return
  const headerItem = itemWrappersByHeaderID.value.get(headerID)
  if (!headerItem) return
  // There are `scrollToPosition` and `scrollToItem`, both not well documented
  recycleScrollerRef.value.scrollToPosition(headerItem.position)
}

onMounted(() => {
  scrollbarWidth.value = getScrollbarWidth()
})

function getScrollbarWidth() {
  // Create a temporary, off-screen element
  const div = document.createElement('div')
  div.style.visibility = 'hidden'
  div.style.overflow = 'scroll' // Force a scrollbar to appear
  div.style.width = '50px' // Any fixed width
  div.style.height = '50px' // Any fixed height
  // So we get the width of the thin style, which we are using
  div.style.scrollbarWidth = 'thin'
  document.body.appendChild(div)

  // Create an inner element and append it to the div
  const innerDiv = document.createElement('div')
  innerDiv.style.width = '100%'
  div.appendChild(innerDiv)

  // Calculate the scrollbar width
  const scrollbarWidth = div.offsetWidth - innerDiv.offsetWidth

  // Remove the temporary div
  document.body.removeChild(div)

  return scrollbarWidth
}
</script>

<template>
  <div style="position: relative; height: 100%">
    <!-- Stick Headers -->
    <div class="sticky-header-holder" :style="{ right: `${scrollbarWidth}px` }">
      <div v-if="currentHeaderItem" class="sticky-header primary-header">
        <slot name="header" :item="currentHeaderItem" />
      </div>
      <div v-if="currentSubHeaderItem" class="sticky-header sub-header">
        <slot name="subheader" :item="currentSubHeaderItem" />
      </div>
    </div>
    <RecycleScroller
      ref="recycleScrollerRef"
      v-slot="{ item: itemWrapper }"
      class="sticky-virtual-scroller"
      :items="itemWrappers"
      size-field="rowHeight"
      key-field="id"
      style="height: 100%"
      :buffer="props.bufferPixels"
    >
      <StickyVirtualScrollerRowWrapper
        v-if="recycleScrollerRef"
        :item="itemWrapper.item"
        :container="recycleScrollerRef"
        class="row-wrapper"
        @visible="onRowVisible"
        @hidden="onRowHidden"
      >
        <!-- Header -->
        <div v-if="itemWrapper.isHeader" class="primary-header">
          <slot name="header" :item="itemWrapper.item" />
        </div>

        <!-- Subheader -->
        <div v-if="itemWrapper.isSubHeader" class="sub-header">
          <slot name="subheader" :item="itemWrapper.item" />
        </div>

        <!-- Content -->
        <div :style="{ height: itemWrapper.rowHeight }">
          <slot name="default" :item="itemWrapper.item" />
        </div>
      </StickyVirtualScrollerRowWrapper>
    </RecycleScroller>
  </div>
</template>

<style scoped lang="scss">
.sticky-header-holder {
  position: absolute;
  top: 0;
  left: 0;

  .sticky-header {
    z-index: 4;
  }
}

.sticky-virtual-scroller {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-gutter: stable; // Insures consistent gutter width even with no scrollbar
  z-index: 3;

  .primary-header {
    top: 0;
    height: v-bind('`${props.headerHeight  }px`');
  }

  .sub-header {
    top: v-bind('`${props.headerHeight  }px`');
    height: v-bind('`${props.subHeaderHeight  }px`');
  }
  .primary-header,
  .sub-header {
    z-index: 1;
  }

  .row-wrapper {
    height: v-bind('`${$attrs["data-row-height"]  }px`');
    z-index: -1;
  }
}
</style>
