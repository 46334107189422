<script lang="ts" setup>
import type { CapacityBucketAndCount } from './types'

const props = defineProps<{ capacityBucket: CapacityBucketAndCount }>()
const truncatedBucketDescription = computed(() => {
  const description = props.capacityBucket.bucket.setDescription
  if (description.length > 13) {
    return description.slice(0, 10) + '...'
  }
  return description
})
</script>

<template>
  <ColoredBadge
    v-tooltip="
      `${props.capacityBucket.bucket.setDescription} capacity for ${capacityBucket.bucket.timeDescription} is ${capacityBucket.bucket.maxAppointments} import appointments and there are ${capacityBucket.numScheduledAppointments} scheduled for this period`
    "
    type="danger"
    effect="dark"
  >
    <i-mdi:alert class="align-text-bottom" />
    Over
    <em>{{ truncatedBucketDescription }}</em>
    capacity by
    {{ capacityBucket.remainingCapacity * -1 }}
    appt<template v-if="capacityBucket.remainingCapacity !== -1">s</template>
  </ColoredBadge>
</template>
