/* eslint-disable no-console */

import pako from 'pako'
import { getMany, set } from 'idb-keyval'
import { DateTime } from 'luxon'

import type Pusher from 'pusher-js'
import { useUserStore } from '~/stores/user'
import type {
  CameraImageUpdatedEvent,
  EmptyAppointmentSlotsLoadedEvent,
  EmptyReturnRulesCheckedEvent,
  EmptyReturnRulesReadingEvent,
  EmptyReturnRulesUpdatedEvent,
  EventName,
  GateScheduleUpdatedEvent,
  GlobalNotificationEvent,
  OrganizationNotificationEvent,
  UserNotificationEvent,
  VesselETAsUpdatedEvent,
  WebsocketChannel,
} from '~/services/apiClient'
import { connectToSoketi } from '~/services/soketi'

export type SoketiEvent =
  | OrganizationNotificationEvent
  | UserNotificationEvent
  | GlobalNotificationEvent
  | EmptyAppointmentSlotsLoadedEvent
  | CameraImageUpdatedEvent
  | GateScheduleUpdatedEvent
  | EmptyReturnRulesCheckedEvent
  | VesselETAsUpdatedEvent
  | EmptyReturnRulesReadingEvent
  | EmptyReturnRulesUpdatedEvent

export type SoketiEventHandler = (event: SoketiEvent) => void
type RawSoketiEventHandler = (event: string) => void
type SoketiConnectionStatus =
  | 'initialized'
  | 'connecting'
  | 'connected'
  | 'disconnected'
  | 'failed'
  | 'unavailable'

const LAST_CONNECTED_KEY = 'soketi_last_connected'
const LAST_DISCONNECTED_KEY = 'soketi_last_disconnected'

async function getLastConnectedAndDisconnected(): Promise<{
  lastConnected: DateTime | null
  lastDisconnected: DateTime | null
}> {
  const [lastConnectedTimestamp, lastDisconnectedTimestamp] = await getMany<
    string | undefined
  >([LAST_CONNECTED_KEY, LAST_DISCONNECTED_KEY])
  return {
    lastConnected: lastConnectedTimestamp
      ? DateTime.fromISO(lastConnectedTimestamp)
      : null,
    lastDisconnected: lastDisconnectedTimestamp
      ? DateTime.fromISO(lastDisconnectedTimestamp)
      : null,
  }
}

async function setLastConnected(date: DateTime): Promise<void> {
  await set(LAST_CONNECTED_KEY, date.toISO())
}

async function setLastDisconnected(date: DateTime): Promise<void> {
  await set(LAST_DISCONNECTED_KEY, date.toISO())
}

function isPublicChannel(channelName: string): boolean {
  return (
    !channelName.startsWith('private-') && !channelName.startsWith('presence-')
  )
}
function useSoketiInner(authHeader: Ref<string | null>) {
  const connected = ref(false)
  const previouslyWasConnected = ref(false)
  const onReconnectCallbacks = ref(new Map<string, Function>())
  const client: Pusher = connectToSoketi()
  const eventHandlers = ref(
    new Map<string, Map<string, RawSoketiEventHandler>>()
  )
  // Public subscriptions
  watch([connected], (isConnected) => {
    if (isConnected) {
      // Create subscriptions and event handlers
      for (const [channelName, channelEventHandlers] of eventHandlers.value) {
        if (!isPublicChannel(channelName)) {
          continue
        }
        // console.log(`Subscribing to ${channelName}`)
        const channel = client.subscribe(channelName)
        for (const [eventName, handler] of channelEventHandlers) {
          channel.bind(eventName, handler)
        }
      }
    }
  })
  // Private subscriptions
  watch([connected, authHeader], (isConnected, authHeader) => {
    if (!authHeader) return
    if (isConnected) {
      // Create subscriptions and event handlers
      for (const [channelName, channelEventHandlers] of eventHandlers.value) {
        if (isPublicChannel(channelName)) {
          continue
        }
        // console.log(`Subscribing to ${channelName}`)
        const channel = client.subscribe(channelName)
        for (const [eventName, handler] of channelEventHandlers) {
          // TODO: make sure there is not already a bound event
          if (isBound(channelName, eventName as EventName)) {
            console.log(
              `Already have a handler for channel ${channelName} and event ${eventName}`
            )
          }
          channel.bind(eventName, handler)
        }
      }
    }
  })

  function bindOnReconnect(callback: Function) {
    // Register a function to run when we've lost connection and then reconnected
    if (onReconnectCallbacks.value.has(callback.name)) {
      throw new Error(
        `Already have a callback with name ${callback.name} for onReconnect`
      )
    }
    onReconnectCallbacks.value.set(callback.name, callback)
  }
  function onSoketiConnectionStateChange({
    previous,
    current,
  }: {
    previous: SoketiConnectionStatus
    current: SoketiConnectionStatus
  }) {
    if (current === 'connected') {
      console.log(`Soketi connected. Previous state: ${previous}`)
      connected.value = true
      setLastConnected(DateTime.now())
      if (previouslyWasConnected.value) {
        // Reconnect case
        console.log('Running onReconnectCallbacks')
        for (const callback of onReconnectCallbacks.value.values()) {
          callback()
        }
      }
      previouslyWasConnected.value = true
    } else {
      console.log(`Soketi disconnected. Previous state: ${previous}`)
      connected.value = false
      setLastDisconnected(DateTime.now())
    }
  }
  // Handle connection/disconnect
  // we shouldn't try to reconnect to org specific channels if we have a new auth header

  client.connection.bind('state_change', onSoketiConnectionStateChange)
  const authorized = computed(() => !!authHeader.value)
  watch(
    authHeader,
    (newAuthHeader) => {
      if (newAuthHeader) {
        client.connection.bind('state_change', onSoketiConnectionStateChange)
      }
    },
    { immediate: true }
  )
  function bind(
    channelName: WebsocketChannel | string,
    eventName: EventName,
    handler: SoketiEventHandler
  ) {
    const wrappedEventHandler = handlerWrapper(handler)
    let channelEventHandlers = eventHandlers.value.get(channelName)
    if (!channelEventHandlers) {
      channelEventHandlers = new Map<string, RawSoketiEventHandler>()
      eventHandlers.value.set(channelName, channelEventHandlers)
    }
    if (channelEventHandlers.has(eventName)) {
      // We could allow multiple event handlers for one event, but for now let's not
      throw new Error(
        `Already have a handler for channel ${channelName} and event ${eventName}`
      )
    }
    channelEventHandlers.set(eventName, wrappedEventHandler)
    if (connected.value) {
      if (!isPublicChannel(channelName) && !authorized.value) return
      // From the Pusher code, it looks totally fine to call .subscribe many times. If
      // we are already subscribed, it's basically a "get subscription" call.
      console.log(
        `Subscribing to ${channelName} and event ${eventName} when binding`
      )
      const channel = client.subscribe(channelName)
      channel.bind(eventName, wrappedEventHandler)
    }
  }
  function unbind(
    channelName: WebsocketChannel | string,
    eventName: EventName,
    handler: Function
  ) {
    const channel = client.subscribe(channelName)
    channel.unbind(eventName, handler)
    const channelEventHandlers = eventHandlers.value.get(channelName)
    if (channelEventHandlers) {
      channelEventHandlers.delete(eventName)
    }
    // Remove and unsubscribe from channel if there are no more event handlers
    if (!channelEventHandlers || channelEventHandlers.size === 0) {
      eventHandlers.value.delete(channelName)
      console.log(`Unsubscribing from ${channelName}`)
      client.unsubscribe(channelName)
    }
  }
  function isBound(
    channelName: WebsocketChannel | string,
    eventName: EventName
  ) {
    const channelEventHandlers = eventHandlers.value.get(channelName)
    if (!channelEventHandlers) {
      return false
    }
    const bound = channelEventHandlers.has(eventName)
    // Double check that the client is subscribed to the channel
    if (bound) {
      const channel = client.subscribe(channelName)
      if (!channel.subscribed) {
        console.warn(
          `Event handler for channel ${channelName} and event ${eventName} is bound, but client is not subscribed to channel`
        )
      }
    }
    return bound
  }
  return {
    connected,
    bind,
    unbind,
    isBound,
    eventHandlers,
    bindOnReconnect,
    getLastConnectedAndDisconnected,
  }
}
// Make sure there is only ever one Soketi connection
export const useSoketiBase = createSharedComposable(useSoketiInner)

export function useSoketi() {
  const userStore = useUserStore()
  const authHeader = toRef(userStore, 'authHeader')
  return useSoketiBase(authHeader)
}

function handlerWrapper(handler: SoketiEventHandler) {
  return function (b64EncodedCompressedEvent: string) {
    const event = decodeEvent(b64EncodedCompressedEvent) as SoketiEvent
    handler(event)
  }
}
function decodeEvent(event: string): any {
  const compressedEvent = base64ToByteArray(event)
  const eventJSON = pako.ungzip(compressedEvent, { to: 'string' })
  return JSON.parse(eventJSON)
}

function base64ToByteArray(base64: string): Uint8Array {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}
