import type { DateTime } from 'luxon'
import { shiftNameFromTime } from '~/logic/shifts'
import type { Appointment } from '~/models/appointments'
import type { CapacityBucket } from '~/models/capacitySets'
import type { Container } from '~/models/containers'
export interface WithCounts {
  numTransactions: number
  numSingleEmptyAppointments: number
  numSingleImportAppointments: number
  numDualTransactions: number
}
export class CapacityBucketAndCount {
  bucket: CapacityBucket
  numScheduledAppointments: number
  constructor(bucket: CapacityBucket) {
    this.bucket = bucket
    this.numScheduledAppointments = 0
  }

  get remainingCapacity(): number {
    return this.bucket.maxAppointments - this.numScheduledAppointments
  }
}

export class HourGroup implements WithCounts {
  startTime: DateTime
  hour: number
  transactions: TransactionGroup[]
  capacityBuckets: CapacityBucketAndCount[]
  numTransactions: number
  numSingleEmptyAppointments: number
  numSingleImportAppointments: number
  numDualTransactions: number
  constructor(startTime: DateTime, capacityBuckets: CapacityBucketAndCount[]) {
    this.startTime = startTime
    this.hour = startTime.hour
    this.transactions = []
    this.capacityBuckets = capacityBuckets
    this.numTransactions = 0
    this.numSingleEmptyAppointments = 0
    this.numSingleImportAppointments = 0
    this.numDualTransactions = 0
  }

  addTransaction(transaction: TransactionGroup) {
    this.transactions.push(transaction)
    this.numTransactions += 1
    if (transaction.empty_in && !transaction.load_out) {
      this.numSingleEmptyAppointments += 1
    }
    if (transaction.empty_in && transaction.load_out) {
      this.numDualTransactions += 1
    }
    if (transaction.load_out && !transaction.empty_in) {
      this.numSingleImportAppointments += 1
    }
  }

  get remainingCapacity(): number {
    let remainingCapacity = Infinity
    for (const capacity of this.capacityBuckets) {
      remainingCapacity = Math.min(
        remainingCapacity,
        capacity.remainingCapacity
      )
    }
    return remainingCapacity
  }

  get overCapacityBuckets(): CapacityBucketAndCount[] {
    return this.capacityBuckets.filter(
      (capacityBucket) => capacityBucket.remainingCapacity < 0
    )
  }

  get overCapacity(): boolean {
    return this.remainingCapacity < 0
  }
}
export class ShiftGroup implements WithCounts {
  shiftName: string
  shiftStart: DateTime
  containersByHour: Map<number, HourGroup>
  numTransactions: number
  numSingleEmptyAppointments: number
  numSingleImportAppointments: number
  numDualTransactions: number
  constructor(shiftStart: DateTime) {
    this.shiftName = shiftNameFromTime(shiftStart)
    this.shiftStart = shiftStart
    this.containersByHour = new Map()
    this.numTransactions = 0
    this.numSingleEmptyAppointments = 0
    this.numSingleImportAppointments = 0
    this.numDualTransactions = 0
  }

  get overCapacityDescriptions(): string[] {
    const descriptions: Set<string> = new Set()
    this.containersByHour.forEach((hourGroup) => {
      for (const capacityBucket of hourGroup.capacityBuckets) {
        if (capacityBucket.remainingCapacity < 0) {
          descriptions.add(capacityBucket.bucket.timeDescription)
        }
      }
    })
    return Array.from(descriptions)
  }

  addTransaction(
    transaction: TransactionGroup,
    capacityBuckets: CapacityBucketAndCount[]
  ) {
    const hour = transaction.startTime.hour
    if (!this.containersByHour.has(hour)) {
      this.containersByHour.set(
        hour,
        new HourGroup(transaction.startTime, capacityBuckets)
      )
    }
    this.containersByHour.get(hour)!.addTransaction(transaction)
    this.numTransactions += 1
    if (transaction.empty_in && !transaction.load_out) {
      this.numSingleEmptyAppointments += 1
    }
    if (transaction.load_out && !transaction.empty_in) {
      this.numSingleImportAppointments += 1
    }
    if (transaction.empty_in && transaction.load_out) {
      this.numDualTransactions += 1
    }
  }
}

export class AppointmentTransactionGroup {
  transaction: TransactionGroup
  empty_in: Container | undefined
  load_out: Container | undefined
  primaryContainer: Container

  capacityBucketAndCount: CapacityBucketAndCount | undefined
  hourGroup: HourGroup
  isFirstRowInHour: boolean
  shiftGroup: ShiftGroup
  isFirstRowInShift: boolean
  key: string
  rowHeight: number
  index: number
  constructor({
    transaction,
    hourGroup,
    shiftGroup,
    isFirstRowInHour,
    isFirstRowInShift,
    index,
  }: {
    transaction: TransactionGroup
    hourGroup: HourGroup
    shiftGroup: ShiftGroup
    isFirstRowInHour: boolean
    isFirstRowInShift: boolean
    index: number
  }) {
    this.transaction = transaction
    this.empty_in = transaction.empty_in
    this.load_out = transaction.load_out
    this.primaryContainer = transaction.load_out ?? transaction.empty_in!
    this.hourGroup = hourGroup
    this.shiftGroup = shiftGroup
    this.isFirstRowInHour = isFirstRowInHour
    this.isFirstRowInShift = isFirstRowInShift
    const mainContainer = this.primaryContainer ?? this.empty_in
    if (!mainContainer) {
      throw new Error('No key found for container')
    }
    this.key = mainContainer.key
    this.rowHeight = mainContainer.rowHeight
    this.index = index
  }
}

export class TransactionGroup {
  empty_in: Container | undefined
  load_out: Container | undefined
  startTime: DateTime
  shiftName: string
  appointments: Appointment[]

  constructor({
    empty_in,
    load_out,
  }: {
    empty_in: Container | undefined
    load_out: Container | undefined
  }) {
    this.empty_in = empty_in
    this.load_out = load_out
    this.appointments = []
    let mainAppointment: Appointment | undefined
    if (empty_in && empty_in.booked_appointment) {
      mainAppointment = empty_in.booked_appointment
    }
    if (load_out && load_out.booked_appointment) {
      mainAppointment = load_out.booked_appointment
    }
    if (!mainAppointment) {
      throw new Error(
        `No booked appointments found for ${empty_in?.number} / ${load_out?.number}`
      )
    }

    this.shiftName = mainAppointment.scheduledShift
    this.startTime = mainAppointment.window_start
  }
}
