import { createGlobalState } from '@vueuse/core'
// TODO: Move, although currently it is coupled to the table
import type { RescheduleInfo } from '~/components/dispatch_table/types'
import { useMutationRequestsStore } from '~/stores/mutationRequests'

export function useRescheduling() {
  const reschedules = createGlobalState(() =>
    ref(new Map<number, RescheduleInfo>())
  )()
  const mutationRequestsStore = useMutationRequestsStore()
  // Whenever there is a mutation request update, map over all the latest mutation
  // updates to our store
  watch(mutationRequestsStore.mutationRequestStatesLookup, () => {
    for (const rescheduleInfo of reschedules.value.values()) {
      if (rescheduleInfo.state) {
        const state = mutationRequestsStore.getLatestMutationRequestState(
          rescheduleInfo.state
        )
        if (state) {
          rescheduleInfo.state = state
        }
      }
    }
  })
  function registerReschedule(rescheduleInfo: RescheduleInfo) {
    reschedules.value.set(rescheduleInfo.originalAppointment.id, rescheduleInfo)
  }
  function getRescheduleInfo(
    appointmentID: number
  ): RescheduleInfo | undefined {
    return reschedules.value.get(appointmentID)
  }

  return { registerReschedule, getRescheduleInfo }
}
