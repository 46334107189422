<script setup lang="ts">
import type { AppointmentTableRow, RescheduleInfo } from './types'
import ReschedulePopover from './ReschedulePopover.vue'
import type { AppointmentTransaction } from '~/models/groupedAppointments'
import CancelAppointmentButton from '~/components/CancelAppointmentButton.vue'
import type { BookingRequestStatus } from '~/stores/mutationRequests'
const props = defineProps<{
  transaction: AppointmentTransaction
  rowData: AppointmentTableRow
  rescheduling: boolean
  reschedulingStatus: BookingRequestStatus | undefined
}>()
const emit = defineEmits<{
  (e: 'rescheduleBegun', event: RescheduleInfo): void
}>()

const rescheduleButtonRef = ref<HTMLElement>()
const reschedulingOptionsOpen = ref(false)

const canCancel = computed(() => {
  if (
    props.reschedulingStatus &&
    (props.reschedulingStatus === 'pending' ||
      props.reschedulingStatus === 'booked')
  )
    return false
  return props.transaction.primary_appointment?.appointment.isScheduled
})
const rescheduleButtonText = computed(() => {
  if (props.rescheduling) {
    switch (props.reschedulingStatus) {
      case undefined:
        return 'Exit Reschedule'
      case 'pending':
        return 'Rescheduling...'
      case 'retrying':
        return 'Retrying Reschedule...'
      case 'rejected':
        return 'Reschedule rejected'
      case 'failed':
        return 'Reschedule failed'
      case 'booked':
        return 'Verifying...'
      case 'verified':
        return 'Rescheduled'
      case 'verification_issue':
        return 'Rescheduled with verification issue'
    }
  }
  return 'Reschedule'
})
const showLoadingSpinner = computed(() => {
  if (!props.rescheduling) return false
  switch (props.reschedulingStatus) {
    case 'pending':
      return true
    case 'retrying':
      return true
    case 'booked':
      return true
    default:
      return false
  }
})
function onClickRescheduleButton() {
  reschedulingOptionsOpen.value = !reschedulingOptionsOpen.value
}
function onRescheduleBegun(rescheduleInfo: RescheduleInfo) {
  emit('rescheduleBegun', rescheduleInfo)
  reschedulingOptionsOpen.value = false
}
</script>

<template>
  <div
    v-if="props.transaction.primary_appointment.appointment.isScheduled"
    class="flex justify-start w-full mr-2"
  >
    <el-button
      v-if="props.transaction.primary_appointment.appointment.is_import_out"
      ref="rescheduleButtonRef"
      type="primary"
      size="small"
      :loading="showLoadingSpinner"
      @click="onClickRescheduleButton"
    >
      {{ rescheduleButtonText }}
    </el-button>
    <el-button
      v-else
      ref="rescheduleButtonRef"
      v-tooltip="
        `Can't reschedule ${
          props.transaction.primary_appointment.appointment.moveType
        }`
      "
      type="primary"
      size="small"
      disabled
    >
      {{ rescheduleButtonText }}
    </el-button>
    <CancelAppointmentButton
      v-if="canCancel"
      :appointment="props.transaction.primary_appointment.appointment"
    >
      <el-button type="danger" size="small" class="ml-1">Cancel</el-button>
    </CancelAppointmentButton>
    <ReschedulePopover
      v-if="reschedulingOptionsOpen && rescheduleButtonRef"
      :appointment="props.transaction.primary_appointment.appointment"
      :row-data="props.rowData"
      :target-element="rescheduleButtonRef"
      @reschedule-begun="onRescheduleBegun"
      @close="reschedulingOptionsOpen = false"
    />
  </div>
</template>
