<script setup lang="ts">
import type { AppointmentTableRow } from './types'
import ClickToCopy from '~/components/ClickToCopy.vue'

const props = defineProps<{
  rowData: AppointmentTableRow
}>()
const pickupNumber = computed(() => {
  if (
    props.rowData.transaction.outbound_appointment &&
    props.rowData.transaction.outbound_appointment.appointment.is_import_out
  ) {
    return props.rowData.transaction.primary_appointment.watch
      .import_appointment_pickup_number
  }
  // TODO: Handle other types of appointments, particularly single empty-in
  // Might need to add `watch.empty_in_appointment_pickup_number`
  // For now, at least handle the gate_code case for single empty-in
  if (
    props.rowData.transaction.inbound_appointment &&
    !props.rowData.transaction.outbound_appointment
  ) {
    // If there is a gate code, return it
    return props.rowData.transaction.inbound_appointment.appointment.gate_code
  }
})
const tooltipText = computed(() => {
  let text
  if (pickupNumber.value) {
    text = `Click to copy pickup number`
  } else {
    text = 'Pickup number is unknown'
  }
  if (pickupNumber.value !== props.rowData.appointmentReferenceDescription) {
    text += `. Appointment # is ${props.rowData.appointmentReferenceDescription}`
  }
  return text
})
</script>

<template>
  <div class="w-full truncate-text">
    <ClickToCopy
      :text-to-copy="pickupNumber ?? '???'"
      :tooltip-text="tooltipText"
      :class="{
        'text-gray-500': !pickupNumber,
      }"
    />
  </div>
</template>

<style scoped>
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
</style>
