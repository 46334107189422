import globalAxios from 'axios'
import { handleAPIError } from '~/apiErrorHandling'
import { useUserStore } from '~/stores/user'
import type { UserModule } from '~/types'

export const install: UserModule = ({ isClient, router }) => {
  globalAxios.interceptors.response.use((response) => response, handleAPIError)
  if (isClient) {
    const userStore = useUserStore()
    router.beforeResolve(async (to, from) => {
      // If we are just moving around the same page, don't do anything
      // TODO: Maybe consider that route changes are not the place to do this anyway?
      // We really want to run all this on page load
      if (from.path === to.path) return
      if (to.meta.auth) {
        if (userStore.authHeader) {
          // Blocks page load until we have user data
          await userStore.loadInfoIfNeeded()
        } else {
          // Redirect to login page
          return { name: 'login' }
        }
      }
      userStore.identifyUserWithServices()
    })
  }
}
