import { DateTime } from 'luxon'
import type {
  GETVesselETA,
  PortalName,
  TerminalName,
} from '~/services/apiClient'
import { getPortalLabel, getTerminalLabel } from '~/constants'

export class VesselETA {
  eta: DateTime
  firstObserved: DateTime
  lastObserved: DateTime
  portal: PortalName
  terminal: TerminalName | undefined
  constructor(raw: GETVesselETA) {
    this.eta = DateTime.fromISO(raw.eta)
    this.firstObserved = DateTime.fromISO(raw.first_observed)
    this.lastObserved = DateTime.fromISO(raw.last_observed!)
    this.portal = raw.portal_name
    this.terminal = raw.terminal
  }

  get portalLabel(): string {
    return getPortalLabel(this.portal)
  }

  get terminalLabel(): string {
    if (!this.terminal) {
      return ''
    }
    return getTerminalLabel(this.terminal)
  }
}
