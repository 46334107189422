import { acceptHMRUpdate, defineStore } from 'pinia'
import type { BaseAppointmentSlot } from '~/models/appointmentSlot'
import type {
  BookingRequest,
  BookingVerificationNotification,
  RescheduleRequest,
  Notification as SoketiNotification,
} from '~/services/apiClient'
import {
  ActionStatus,
  ActionType,
  NotificationType,
} from '~/services/apiClient'

export type BookingRequestStatus =
  | 'pending'
  | 'retrying'
  | 'rejected'
  | 'failed'
  | 'booked'
  | 'verified'
  | 'verification_issue'

export interface MutationRequestState {
  requestID: number
  actionType: ActionType
  selectedSlot: BaseAppointmentSlot
  originalAppointmentID: number | undefined // For reschedules
  status: BookingRequestStatus
  bookingRequest: BookingRequest | undefined
  rescheduleRequest: RescheduleRequest | undefined
  appointmentID: number | undefined
  latestMessage: string | undefined
}

function makeLookupKey(actionType: ActionType, requestID: number): string {
  return `${actionType}_${requestID}`
}

function makeLookupKeyFromState(state: MutationRequestState): string {
  return makeLookupKey(state.actionType, state.requestID)
}

export function detectActionType(
  mutationRequest: RescheduleRequest | BookingRequest
): ActionType {
  if (mutationRequest.action_type === 'reschedule') {
    return ActionType.Reschedule
  } else if (mutationRequest.action_type === 'book') {
    return ActionType.Book
  } else if (mutationRequest.action_type === 'book_empty') {
    return ActionType.BookEmpty
  } else {
    // While the typing says `.action_type` can be `undefined`, in reality it is always
    // filled out. The typing is a byproduct of how default arguments are interpreted
    // by the API client.
    throw new Error(`Unknown action type: ${mutationRequest.action_type}`)
  }
}

export const useMutationRequestsStore = defineStore('mutation-requests', () => {
  const mutationRequestStatesLookup = ref(
    new Map<string, MutationRequestState>()
  )

  function handleAppointmentVerificationEvent(
    notification: SoketiNotification
  ) {
    if (notification.event_type !== NotificationType.BookingVerification) {
      throw new Error(`Invalid event type ${notification.event_type}`)
    }
    const verificationInformation =
      notification.object as BookingVerificationNotification
    const mutationRequest = verificationInformation.action_request
    const actionType = detectActionType(mutationRequest)

    if (mutationRequest.reschedule_request_id) {
      return ActionType.Reschedule
    }
    const key = makeLookupKey(actionType, mutationRequest.id)
    const mutationRequestState = mutationRequestStatesLookup.value.get(key)
    if (!mutationRequestState) {
      console.warn(`No mutation request found for ${key}`)
      return
    }
    if (notification.level === 'success') {
      mutationRequestState.status = 'verified'
    } else {
      mutationRequestState.status = 'verification_issue'
    }
    mutationRequestState.appointmentID = mutationRequest.appointment_id
    mutationRequestState.latestMessage = `${notification.title}`
    if (notification.message) {
      mutationRequestState.latestMessage += `: ${notification.message}`
    }
    mutationRequestStatesLookup.value.set(key, mutationRequestState)
  }
  function handleBookingRequestUpdatedEvent(notification: SoketiNotification) {
    if (notification.event_type !== NotificationType.BookingRequestUpdated) {
      throw new Error(`Invalid event type ${notification.event_type}`)
    }
    // We send BOTH booking and reschedule requests under `BookingRequestUpdated`, so
    // we need to identify which type of update we are dealing with
    // TODO: At some point it might be good to send these under their own `event_type`
    const mutationRequest = notification.object as
      | RescheduleRequest
      | BookingRequest
    const requestID = mutationRequest.id
    const actionType = detectActionType(mutationRequest)
    let key = makeLookupKey(actionType, requestID)
    if (actionType === ActionType.Book) {
      const reschedule_request_id = (mutationRequest as BookingRequest)
        .reschedule_request_id
      if (reschedule_request_id) {
        // This is a "cancel-and-rebook" reschedule request, we should update the
        // reschedule request
        // NOTE: We do not send updates for the reschedule request for cancel-and-rebook
        // which is why we need this hack. Could consider sending reschedule updates,
        // but might be confusing to the user to receive both reschedule and booking
        // updates. One solution would be to make the reschedule notification have no
        // message or title to make it "silent"
        key = makeLookupKey(ActionType.Reschedule, reschedule_request_id)
      }
    }
    const mutationRequestState = mutationRequestStatesLookup.value.get(key)
    if (!mutationRequestState) {
      console.warn(`No mutation request found for '${key}'`)
      return
    }
    if (actionType === ActionType.Reschedule) {
      mutationRequestState.rescheduleRequest =
        mutationRequest as RescheduleRequest
    } else if (actionType === ActionType.Book) {
      mutationRequestState.bookingRequest = mutationRequest as BookingRequest
    }
    switch (mutationRequest.status) {
      case ActionStatus.Completed:
        mutationRequestState.status = 'booked'
        break
      case ActionStatus.Failed:
        mutationRequestState.status = 'failed'
        break
      case ActionStatus.Rejected:
        mutationRequestState.status = 'rejected'
        break
      default:
        console.warn('Unhandled booking request status', mutationRequest.status)
    }
    // Sometimes we get the appointment immediately
    // TODO: Is this right?
    mutationRequestState.appointmentID = mutationRequest.appointment_id
    mutationRequestState.latestMessage = `${notification.title}`
    if (notification.message) {
      mutationRequestState.latestMessage += `: ${notification.message}`
    }
  }
  function registerNewMutationRequest(state: MutationRequestState) {
    const key = makeLookupKeyFromState(state)
    mutationRequestStatesLookup.value.set(key, state)
  }

  function getLatestMutationRequestState(
    state: MutationRequestState
  ): MutationRequestState | undefined {
    return mutationRequestStatesLookup.value.get(makeLookupKeyFromState(state))
  }
  return {
    mutationRequestStatesLookup,
    // This must be called first to allow us to then handle updates
    registerNewMutationRequest, // TODO: Rename to use "mutation"
    handleBookingRequestUpdatedEvent,
    handleAppointmentVerificationEvent,
    getLatestMutationRequestState,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMutationRequestsStore, import.meta.hot)
  )
}
