<script lang="ts" setup>
import { computed, ref } from 'vue'
import type { Appointment } from '~/models/appointments'
import { TERMINALS_REQUIRING_APPOINTMENT_CHECK_IN } from '~/constants'

const props = defineProps<{
  appointment: Appointment
}>()

const emits = defineEmits<{
  (event: 'checkIn', appointment: any): void
}>()

// Compute if the appointment can be checked in
const canCheckInAppointment = computed(() => {
  return (
    props.appointment.terminal &&
    TERMINALS_REQUIRING_APPOINTMENT_CHECK_IN.has(props.appointment.terminal) &&
    props.appointment.check_in_status === false
  )
})

// Compute the check-in message and tooltip
const checkInMessage = computed(() => {
  if (
    props.appointment.terminal &&
    TERMINALS_REQUIRING_APPOINTMENT_CHECK_IN.has(props.appointment.terminal)
  ) {
    if (props.appointment.check_in_status === true) {
      return {
        message: '✅ Checked In',
        type: 'info' as const,
        tooltip: 'Appointment is already checked in.',
      }
    } else if (props.appointment.check_in_status === false) {
      return {
        message: '❌ Not Checked In',
        type: 'warning' as const,
        tooltip: 'Click to check in.',
      }
    }
  }
  return null
})

const checkInAppointmentDialogOpen = ref(false)

const checkInAppointment = () => {
  if (!canCheckInAppointment.value) return
  emits('checkIn', props.appointment)
  checkInAppointmentDialogOpen.value = true
}
</script>

<template>
  <ColoredBadge
    v-if="checkInMessage"
    v-tooltip="checkInMessage.tooltip"
    :type="checkInMessage.type"
    class="cursor-pointer"
    data-testid="check-in-appointment-badge"
    @click.stop="checkInAppointment"
  >
    {{ checkInMessage.message }}
    <i-mdi:pencil
      v-if="canCheckInAppointment"
      class="align-text-top h-13px text-gray-400 -mr-1"
    />
    <CheckInAppointmentDialog
      v-if="checkInAppointmentDialogOpen"
      v-model="checkInAppointmentDialogOpen"
      append-to-body
      :appointment="props.appointment"
    />
  </ColoredBadge>
</template>
