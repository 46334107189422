<script setup lang="ts">
import { computed, ref } from 'vue'
import { ArrowDown } from '@element-plus/icons-vue'
import { getStatusLabel } from '~/constants'
import { AppointmentStatus } from '~/services/apiClient'

const dropdownVisible = ref(false)

const handleDropdown = (visible: boolean) => {
  dropdownVisible.value = visible
}

const defaultSelectedStatuses: AppointmentStatus[] = [
  AppointmentStatus.Scheduled,
  AppointmentStatus.InProgress,
  AppointmentStatus.Completed,
  AppointmentStatus.Missed,
]

const statuses = defineModel<AppointmentStatus[]>({})

const options = [
  AppointmentStatus.Scheduled,
  AppointmentStatus.InProgress,
  AppointmentStatus.Completed,
  AppointmentStatus.Missed,
  AppointmentStatus.Cancelled,
  AppointmentStatus.Aborted,
  AppointmentStatus.SchedulingError,
]

if (!statuses.value || statuses.value.length === 0) {
  statuses.value = defaultSelectedStatuses
}

const selectedStatusesTooltip = computed(() => {
  if (statuses.value === undefined) {
    return ''
  }
  return statuses.value.length > 0
    ? statuses.value.map(getStatusLabel).join(', ')
    : ''
})
</script>

<template>
  <el-dropdown trigger="click" @visible-change="handleDropdown">
    <el-button
      v-tooltip="
        statuses && statuses.length > 0
          ? selectedStatusesTooltip
          : 'Select statuses to filter by'
      "
      :class="{ 'button-active': statuses && statuses.length > 0 }"
      class="button-with-arrow"
    >
      <span>Status</span>
      <el-icon
        class="dropdown-arrow"
        :class="{ 'arrow-rotated': dropdownVisible }"
      >
        <ArrowDown />
      </el-icon>
    </el-button>
    <template #dropdown>
      <div class="checkbox-container">
        <el-checkbox-group v-model="statuses" class="checkbox-group">
          <el-checkbox
            v-for="status in options"
            :key="status"
            :value="status"
            class="checkbox-item"
          >
            <StatusCell :status="status" />
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </template>
  </el-dropdown>
</template>

<style>
.button-with-arrow {
  display: inline-flex;
  align-items: center;
  justify-content: left;
  gap: 4px;
  line-height: 1;
}

.dropdown-arrow {
  font-size: 14px;
  line-height: 1;
  margin-left: 4px;
  margin-top: 1px;
  top: 0;
  position: relative;
  transition: transform 0.3s ease;
}

.arrow-rotated {
  transform: rotate(180deg);
}

.button-active {
  outline: none;
  background-color: #ecf5ff;
  color: #409eff;
  border-color: #409eff;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkbox-item {
  margin: 4px 0;
  width: 100%;
}

.custom-tooltip {
  background-color: rgba(64, 158, 255, 255) !important;
  color: #ffffff !important;
  border: 1px solid rgba(64, 158, 255, 255) !important;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  width: auto;
}
</style>
