import { ElNotification } from 'element-plus'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { DateTime } from 'luxon'
import { useUserStore } from './user'
import { useSoketi } from '#compositions/useSoketi'
import { Truck } from '~/models/trucks'
import type { GETTruck } from '~/services/apiClient'
import { TrucksApi } from '~/services/apiClient'
import { standardizeLicensePlate } from '~/utils'

export const useTrucksStore = defineStore('trucks', () => {
  const soketi = useSoketi()
  const userStore = useUserStore()

  const trucksById = ref(new Map<number, Truck>())
  const trucksByLicensePlate = ref(new Map<string, Truck>())
  const loadingPromise = ref(null as Promise<void> | null)
  const lastLoadedAt = ref(null as DateTime | null)
  const lastReconnectedToSoketi = ref(null as DateTime | null)

  const trucks = computed(() => Array.from(trucksById.value.values()))
  const loading = computed(() => loadingPromise.value !== null)

  function addTrucksToStore(trucks: GETTruck[]) {
    trucks.forEach((rawTruck) => {
      const truck = new Truck(rawTruck, userStore.demo_mode)
      trucksById.value.set(truck.id, truck)
      trucksByLicensePlate.value.set(truck.licensePlate, truck)
    })
  }
  function clearTrucks() {
    trucksById.value.clear()
    trucksByLicensePlate.value.clear()
  }

  function load(includeArchived: boolean = false): Promise<void> {
    const api = new TrucksApi()
    clearTrucks()
    loadingPromise.value = api
      .getTrucksTrucksGet(includeArchived)
      .then((response) => {
        addTrucksToStore(response.data)
        lastLoadedAt.value = DateTime.now()
      })
      .finally(() => {
        loadingPromise.value = null
      })
    return loadingPromise.value
  }
  function loadIfNotLoadedSinceReconnect(): Promise<void> {
    if (!loadingPromise.value) {
      if (
        lastLoadedAt.value &&
        (!lastReconnectedToSoketi.value ||
          lastReconnectedToSoketi.value < lastLoadedAt.value)
      ) {
        // Our data is up-to-date, no need to reload
        return Promise.resolve()
      }
      return load()
    }
    return loadingPromise.value
  }
  soketi.bindOnReconnect(() => {
    lastReconnectedToSoketi.value = DateTime.now()
  })

  async function saveNewTruck(newTruck: {
    licensePlate: string
    name?: string
  }) {
    const truckAPI = new TrucksApi()
    let addedTrucks: GETTruck[] = []
    try {
      const resp = await truckAPI.createTrucksTrucksPost([
        {
          license_plate_number: newTruck.licensePlate,
          name: newTruck.name,
        },
      ])
      addedTrucks = resp.data
    } catch (error) {
      ElNotification.error({
        title: 'Error',
        message: error instanceof Error ? error.message : 'Failed to add truck',
      })
      throw error
    }
    addTrucksToStore(addedTrucks)
    let truckDesc = `'${newTruck.licensePlate}'`
    if (newTruck.name) {
      truckDesc += ` (${newTruck.name})`
    }
    ElNotification.success({
      title: 'Success',
      message: `Truck with plate ${truckDesc} added`,
    })
    return addedTrucks
  }

  function updateExistingTruck(trucks: GETTruck[]): Promise<void> {
    const truckAPI = new TrucksApi()
    return truckAPI
      .editTruckTrucksPut(trucks)
      .then((response) => {
        addTrucksToStore(response.data)
        ElNotification.success({
          title: 'Success',
          message: 'Truck updated successfully',
        })
      })
      .catch(() => {
        ElNotification.error({
          title: 'Error',
          message: 'Failed to update truck name',
        })
      })
  }
  function getTruck(
    truckId: number | null,
    licensePlate: string
  ): Truck | null {
    const standardizedPlate = standardizeLicensePlate(licensePlate)
    let truck = undefined as Truck | undefined
    if (truckId) {
      truck = trucksById.value.get(truckId)
    }
    // Fallback to lookup by license plate. This will be useful for new plates that
    // are entered manually, and appointments that have not had their truck_id set
    // yet.
    if (!truck && standardizedPlate) {
      truck = trucksByLicensePlate.value.get(standardizedPlate)
    }
    if (truck) return truck
    return null
  }
  function getTruckDescription(
    truckId: number | null,
    licensePlate: string
  ): string {
    const truck = getTruck(truckId, licensePlate)
    let desc = licensePlate
    if (truck && truck.name) {
      desc += ` (${truck.name})`
    }
    return desc
  }

  return {
    load,
    saveNewTruck,
    updateExistingTruck,
    addTrucksToStore,
    getTruck,
    getTruckDescription,
    loadIfNotLoadedSinceReconnect,
    trucks,
    loading,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTrucksStore, import.meta.hot))
}
