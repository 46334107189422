<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { CapacityBucket, DayOfWeek } from '~/models/capacitySets'

const props = defineProps<{
  buckets: CapacityBucket[]
}>()

const days_of_the_week: DayOfWeek[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
const selected_day_of_the_week = ref('Monday' as DayOfWeek)
const duplicateDialogOpen = ref(false)
const referenceDate = DateTime.now()
const buckets_for_day = computed(() => {
  return props.buckets.filter(
    (bucket) =>
      bucket.getStart(referenceDate).weekdayLong ===
      selected_day_of_the_week.value
  )
})
function parseInt(value: string) {
  let int = Number.parseInt(value)
  if (Number.isNaN(int) || int < 0) {
    int = 0
  }
  return int
}
function handleChange(value: any, bucket_ix: number) {
  const parsedValue = parseInt(value)
  if (parsedValue !== value) {
    buckets_for_day.value[bucket_ix].maxAppointments = parsedValue
  }
}
watch(buckets_for_day, (newValue) => {
  // throwing an error for this case as this bug is happening
  if (buckets_for_day.value.length === 0 && props.buckets.length > 0) {
    throw new Error(
      `No buckets found for day ${selected_day_of_the_week.value} on ${referenceDate.toFormat('yyyy-MM-dd')}` +
        ` local timezone: ${referenceDate.zoneName}` +
        ` ${props.buckets.length} buckets were passed into component`
    )
  }
})
</script>

<template>
  <el-form label-position="top" inline>
    <el-form-item label="Day:">
      <el-select v-model="selected_day_of_the_week" class="max-w-30; min-w-25">
        <el-option
          v-for="day_of_the_week in days_of_the_week"
          :key="day_of_the_week"
          :label="day_of_the_week"
          :value="day_of_the_week"
        />
      </el-select>
    </el-form-item>
    <el-row class="items-end">
      <el-form-item
        v-for="(bucket, index) in buckets_for_day"
        :key="bucket.startMinuteOfWeek"
        :label="bucket.getStart(referenceDate).toFormat('ha')"
        style="margin-right: 4px"
      >
        <el-input
          v-model="bucket.maxAppointments"
          type="text"
          class="max-w-12 disable-arrows"
          :data-testid="`capacity-input-${index}`"
          :min="0"
          @change="(value: any) => handleChange(value, index)"
        />
      </el-form-item>
      <el-button
        type="primary"
        class="mb-18px"
        data-testid="duplicate-capacity-day-button"
        @click="duplicateDialogOpen = true"
      >
        Duplicate
        <el-icon class="el-icon--right"><i-mdi:content-copy /></el-icon>
      </el-button>
    </el-row>

    <DuplicateDialog
      v-if="duplicateDialogOpen"
      :target-day="selected_day_of_the_week"
      @close="duplicateDialogOpen = false"
    />
  </el-form>
</template>
