<script setup lang="ts">
import EditLicensePlateDialog from '~/components/dialogs/EditLicensePlateDialog.vue'

import useTruckDescription from '~/compositions/useTruckDescription'
import {
  TERMINALS_WHERE_LICENSE_PLATE_CAN_BE_EDITED,
  TERMINALS_WHERE_LICENSE_PLATE_IS_NOT_USED_WHEN_BOOKING,
} from '~/constants'
import { AppointmentTransaction } from '~/models/groupedAppointments'
import { useGroupedAppointmentsStore } from '~/stores/groupedAppointments'
import { useElementHover } from '@vueuse/core'
const props = defineProps<{
  transaction: AppointmentTransaction
}>()

const groupedAppointmentsStore = useGroupedAppointmentsStore()
const editing = ref(false)
const licensePlateRef = ref()
const licensePlateHovered = useElementHover(licensePlateRef)

const licensePlate = computed(() => {
  return props.transaction.primary_appointment?.appointment
    .truck_license_plate_number
})
const truckID = computed(() => {
  return props.transaction.primary_appointment?.appointment.truck_id
})
const terminalUsesLicensePlates = computed(() => {
  return (
    !TERMINALS_WHERE_LICENSE_PLATE_IS_NOT_USED_WHEN_BOOKING.has(
      props.transaction.terminal
    ) ||
    TERMINALS_WHERE_LICENSE_PLATE_CAN_BE_EDITED.has(props.transaction.terminal)
  )
})
const truckDescription = useTruckDescription(truckID, licensePlate)
const filteringToThisPlate = computed(() => {
  return licensePlate.value === groupedAppointmentsStore.filters.licensePlate
})

function openEditPlateDialog() {
  editing.value = true
}

function filterToTruck() {
  if (licensePlate.value) {
    let newPlate: string | undefined
    if (filteringToThisPlate.value) {
      newPlate = undefined
    } else {
      newPlate = licensePlate.value
    }
    groupedAppointmentsStore.filters = {
      ...groupedAppointmentsStore.filters,
      licensePlate: newPlate,
    }
  }
}
</script>

<template>
  <div
    v-if="!terminalUsesLicensePlates"
    class="static-text"
    v-tooltip="
      `${transaction.terminal_name} does not require license plate information`
    "
  >
    Not Applicable
  </div>
  <div v-else class="cursor-pointer w-full">
    <div
      ref="licensePlateRef"
      v-if="licensePlate"
      class="flex items-center justify-between relative"
      @click="openEditPlateDialog"
    >
      <div class="truck-description">
        {{ truckDescription }}
      </div>
      <div
        v-if="licensePlateHovered || filteringToThisPlate"
        style="position: absolute; top: -3px; right: 16px"
        class="text-lg bg-white rounded-full p-1"
        data-testid="filter-to-truck-shortcut"
        @click.prevent.stop="filterToTruck"
      >
        <i-mdi:filter-variant-remove v-if="filteringToThisPlate" />
        <i-mdi:filter-variant v-else />
      </div>
      <i-mdi:chevron-down />
    </div>
    <el-button
      v-else-if="props.transaction.primary_appointment.appointment.isScheduled"
      type="primary"
      size="small"
      plain
      class="w-full"
      @click="openEditPlateDialog"
    >
      + Add License Plate</el-button
    >
    <EditLicensePlateDialog
      v-if="editing"
      :model-value="true"
      @update:model-value="editing = $event"
      :append-to-body="true"
      :appointment="props.transaction.primary_appointment?.appointment"
    />
  </div>
</template>

<style scoped lang="scss">
.truck-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);
}
.static-text {
  @apply text-gray-400 text-xs w-full;
}
</style>
