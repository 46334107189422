import type { SoketiEvent } from './useSoketi'
import { useSoketi } from '#compositions/useSoketi'
import { CameraImage } from '~/models/cameraImage'
import type {
  CameraImageUpdatedEvent,
  TerminalName,
} from '~/services/apiClient'
import { CamerasApi, EventName, WebsocketChannel } from '~/services/apiClient'

export function useLiveCameraStreams(terminal?: TerminalName) {
  const images: Map<string, CameraImage> = reactive(new Map())
  const imagesList = computed(() => Array.from(images.values()))
  function handleMessage(event: SoketiEvent) {
    const imageEvent = event as CameraImageUpdatedEvent
    const img = new CameraImage(imageEvent.image)
    if (images.has(img.key)) images.set(img.key, img)
  }

  onMounted(async () => {
    const soketi = useSoketi()
    soketi.bind(
      WebsocketChannel.GateCameras,
      EventName.CameraImageUpdated,
      handleMessage
    )
    const api = new CamerasApi()
    let promise
    if (terminal) {
      promise =
        api.getStreamsForTerminalCameraStreamsTerminalTerminalNameGet(terminal)
    } else {
      promise = api.getStreamsCameraStreamsGet()
    }
    promise.then((resp) => {
      for (const rawImage of resp.data) {
        const img = new CameraImage(rawImage)
        images.set(img.key, img)
      }
    })
  })

  onUnmounted(() => {
    const soketi = useSoketi()
    soketi.unbind(
      WebsocketChannel.GateCameras,
      EventName.CameraImageUpdated,
      handleMessage
    )
  })
  return { images, imagesList }
}
