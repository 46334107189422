<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  eta: string | null
  containerCount: number
}>()

const formattedDate = computed(() => {
  if (!props.eta) return 'No ETA'

  const date = new Date(props.eta)

  if (isNaN(date.getTime())) {
    console.warn(`Invalid ETA received: ${props.eta}`)
    return 'No ETA'
  }

  return `${date.toLocaleDateString(undefined, { weekday: 'short' })} ${date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' })}`
})

const tooltip = computed(() => {
  return `${props.containerCount} container${props.containerCount !== 1 ? 's' : ''} scheduled for ${formattedDate.value}`
})
</script>

<template>
  <BaseAppointmentHeader :tooltip="tooltip" :is-sub-header="false">
    <template #left>
      {{ formattedDate }}
    </template>
    <template #right>
      {{ containerCount }} container{{ containerCount !== 1 ? 's' : '' }}
    </template>
  </BaseAppointmentHeader>
</template>
