import { DateTime } from 'luxon'
import { faker } from '@faker-js/faker/locale/en'
import type { ActionStatus } from './actionRequests'
import { statusFromBookingRequestStatus } from './actionRequests'
import type {
  GETBookingStyleRequestWithApptNum as GETBookingRequest,
  SimpleUser,
  TerminalName,
} from '~/services/apiClient/api'

export default class BookingRequest {
  id: number
  created: DateTime
  window_start: DateTime
  status: ActionStatus
  terminal: TerminalName
  container_number: string
  action_type: 'book' | 'book_empty' | 'reschedule'
  error_message?: string
  requested_by?: SimpleUser
  entered_in_tms: boolean
  entered_in_tms_by?: SimpleUser
  entered_in_tms_time?: DateTime
  auto_booked: boolean
  appointment_number?: string

  constructor(raw: GETBookingRequest, demoMode: boolean) {
    this.id = raw.id
    this.created = DateTime.fromISO(raw.created)
    this.terminal = raw.terminal
    this.window_start = DateTime.fromISO(raw.window_start)
    this.status = statusFromBookingRequestStatus(raw.status)
    this.container_number = raw.container_number
    this.action_type = raw.action_type
    this.error_message = raw.error_message
    if (demoMode) {
      this.requested_by = {
        id: 1,
        first_name: faker.person.firstName(),
        last_name: faker.person.lastName(),
      }
    } else {
      this.requested_by = raw.requested_by
    }
    this.entered_in_tms_time = raw.entered_in_tms_time
      ? DateTime.fromISO(raw.entered_in_tms_time)
      : undefined
    this.entered_in_tms = raw.entered_in_tms_time !== null
    this.entered_in_tms_by = raw.entered_in_tms_by
    this.auto_booked = Boolean(raw.auto_book_request_id)
    this.appointment_number = raw.appointment_terminal_reference
  }
}
