import type { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import { GateScheduleReading } from '~/models/gateSchedules'
import type { TerminalName } from '~/services/apiClient'
import { GateSchedulesApi } from '~/services/apiClient'

export const useGateScheduleReadingsHistory = defineStore(
  'gate_schedules-readings-history',
  {
    state: () => {
      return {
        readings: [] as GateScheduleReading[],
        loading: false,
      }
    },
    actions: {
      load({
        terminalName,
        observedAfter,
        observedBefore,
      }: {
        terminalName: TerminalName
        observedAfter?: DateTime
        observedBefore?: DateTime
      }) {
        this.readings = []
        this.loading = true
        const api = new GateSchedulesApi()
        return api
          .getReadingsHistoryGateSchedulesReadingsTerminalHistoryGet(
            terminalName,
            observedAfter?.toISO(),
            observedBefore?.toISO()
          )
          .then((resp) => {
            this.readings = resp.data.map((reading) => {
              return new GateScheduleReading(reading)
            })
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
)
