<script lang="ts" setup>
import { computed, ref } from 'vue'
import { ElNotification } from 'element-plus'
import BaseDialog from './BaseDialog.vue'
import type { Appointment } from '~/models/appointments'
import { AppointmentBookingApi } from '~/services/apiClient'
import LicensePlateSelector from '~/components/inputs/LicensePlateSelector.vue'
import ExistingLicensePlateFormDisplay from '~/components/display/ExistingLicensePlateFormDisplay.vue'

const props = defineProps<{
  modelValue: boolean
  appointment: Appointment
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'close'): void
}>()
const dialogOpen = useVModel(props, 'modelValue', emit)
const originalLicensePlate = ref(
  props.appointment.truck_license_plate_number || ''
)
const licensePlate = ref(props.appointment.truck_license_plate_number || '')
const newLicensePlate = ref(licensePlate.value)

const manualInput = ref(false)

function handleLicensePlateChangeOnInput(newValue: string | undefined | null) {
  newLicensePlate.value = newValue?.trim() || ''
}

function handleLicensePlateChangeOnUpdate(newValue: string | undefined | null) {
  newLicensePlate.value = newValue?.trim() || ''
  manualInput.value = true
}

const platedChanged = computed(() => {
  return (
    !!newLicensePlate.value &&
    newLicensePlate.value.trim().length > 0 &&
    newLicensePlate.value.trim() !== (originalLicensePlate.value?.trim() || '')
  )
})
function updateLicensePlate() {
  if (!platedChanged.value) {
    ElNotification.warning('License plate has not changed')
    return
  }
  const api = new AppointmentBookingApi()
  api
    .editLicensePlateAppointmentsBookingEditLicensePlatePost({
      appointment_id: props.appointment.id,
      license_plate: newLicensePlate.value || '',
    })
    .finally(() => {
      ElNotification.success({ title: 'Update license plate begun' })
      dialogOpen.value = false
    })
}
</script>

<template>
  <BaseDialog
    v-if="dialogOpen"
    v-model="dialogOpen"
    :title="`Update License Plate for ${appointment.container_number}`"
    :width="540"
    @close="emit('close')"
  >
    <el-form class="edit-license-plate-dialog-form">
      <div class="mb-8">
        <el-form-item label="Terminal:">
          {{ appointment.terminal_label }}
        </el-form-item>
        <ExistingLicensePlateFormDisplay
          :license-plate="originalLicensePlate"
        />
      </div>
      <!-- Edit license plate dropdown, select when open -->
      <el-form-item label="License Plate:" style="margin-right: 0px">
        <LicensePlateSelector
          v-model="licensePlate"
          :terminal="appointment.terminal"
          :autofocus="true"
          :show-icon="false"
          @update:model-value="handleLicensePlateChangeOnUpdate"
          @input="handleLicensePlateChangeOnInput"
        />
      </el-form-item>
    </el-form>
    <!-- Give the user a hint/tip that they can offer any value, not just those in the dropdown -->
    <el-alert type="info" show-icon :closable="false">
      You can enter any license plate number, not just those in the dropdown.
    </el-alert>
    <template #action-button>
      <el-button
        v-tooltip="{
          content: 'Selected a different license plate to update',
          disabled: platedChanged,
        }"
        size="large"
        type="primary"
        :disabled="!platedChanged"
        @click="updateLicensePlate"
      >
        <template v-if="newLicensePlate">
          Update Plate to "{{ newLicensePlate }}"
        </template>
        <template v-else> Select a plate </template>
      </el-button>
    </template>
  </BaseDialog>
</template>

<style lang="scss">
.edit-license-plate-dialog-form .el-form-item__label {
  font-weight: bold;
}
.edit-license-plate-dialog-form .el-form-item {
  margin-right: 15px;
}
</style>
