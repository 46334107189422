<script lang="ts" setup>
import startCase from 'lodash-es/startCase'
import type { DateTime } from 'luxon'
import { useAppointmentStore } from '~/stores/appointments'
import { appointmentStatusColor } from '~/utils'
import { useMakeDualTooltip } from '~/compositions/createDualToolTip'

const props = defineProps({
  containerNumber: { type: String, required: true },
  // Allows filtering out old appointments
  after: { type: Object as () => DateTime, required: false },
})
const appointmentStore = useAppointmentStore()
onMounted(() => {
  appointmentStore.load({
    container_numbers: [props.containerNumber],
    after: props.after,
  })
})
const { makeDualTooltip } = useMakeDualTooltip()
</script>

<template>
  <el-table
    v-loading="appointmentStore.loading"
    :data="appointmentStore.appointments"
    stripe
    class="w-full break-words"
    empty-text="No appointments"
  >
    <el-table-column prop="window_date" label="Date • Time" :width="212">
      <template #default="{ row }">
        {{ row.window_date }} • {{ row.window_description }}
      </template>
    </el-table-column>

    <el-table-column prop="appointment_number" label="Appt#" :min-width="70">
      <template #default="{ row }">
        <el-tooltip :content="row.display_terminal_reference" placement="top">
          <ClickToCopy
            :text-to-copy="row.display_terminal_reference"
            class="truncate block"
          >
            #{{ row.display_terminal_reference }}
          </ClickToCopy>
        </el-tooltip>
      </template>
    </el-table-column>

    <el-table-column prop="status" label="Terminal • Status" :width="145">
      <template #default="{ row }">
        <div class="flex flex-wrap">
          <div>
            {{ row.terminal_name }}
            •
          </div>
          <div>
            <span
              :style="{ color: appointmentStatusColor(row.status) }"
              class="text-white"
            >
              {{ startCase(row.status) }}
            </span>
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column prop="type" label="Type" :width="84">
      <template #default="{ row }">
        <span
          v-if="row.linked_appointments.length"
          v-tooltip="makeDualTooltip(row.linked_appointments)"
        >
          {{ row.type }}
          <DualsIcon class="align-middle" />
        </span>
        <template v-else>{{ row.type }}</template>
      </template>
    </el-table-column>

    <el-table-column
      prop="created_at_terminal_fmtd"
      label="Created"
      :width="100"
    >
      <template #default="{ row }">
        <div class="text-gray-400">
          {{ row.created_at_terminal_fmtd }}
        </div>
      </template>
    </el-table-column>

    <el-table-column prop="booked_by" label="User">
      <template #default="{ row }">
        <el-tooltip
          v-if="row.booked_by"
          :content="`Booked by ${row.booked_by}`"
          class="text-gray-400"
          placement="top"
        >
          {{ row.booked_by }}
        </el-tooltip>
        <el-tooltip
          v-else
          content="Booking user is unknown because appointment was not booked on Dray Dog"
          class="text-gray-400"
          placement="top"
        >
          ???
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>
