<script lang="ts" setup></script>

<template>
  <div class="flex">
    <!-- Left Pane -->
    <div class="mr-3px basis-1/2">
      <slot name="left" />
    </div>
    <!-- Right Pane -->
    <!-- The overflow-x-auto helps avoid a table preventing us from shrinking back down
     on narrowing of window width -->
    <div class="ml-3px basis-1/2 overflow-x-auto" style="min-height: 20vh">
      <slot name="right" />
    </div>
  </div>
</template>
