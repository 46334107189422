<script setup lang="ts">
import Spinner from './Spinner.vue'
import ClickToCopy from '~/components/ClickToCopy.vue'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import type { MutationRequestState } from '~/stores/mutationRequests'
const props = defineProps<{
  colspan: number
  bookingRequestState: MutationRequestState
  bookedAppointment?: AppointmentWithContainerInfo
}>()
const bookingRowColor = computed(() => {
  if (props.bookingRequestState.status === 'pending') {
    return 'bg-yellow-100'
  } else if (props.bookingRequestState.status === 'rejected') {
    return 'bg-orange-100'
  } else if (props.bookingRequestState.status === 'booked') {
    return 'bg-green-100'
  } else if (props.bookingRequestState.status === 'verified') {
    return 'bg-green-300'
  } else if (props.bookingRequestState.status === 'failed') {
    return 'bg-red-100'
  } else {
    return ''
  }
})
function addSpacesToCamelCase(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2')
}
const spinnerWidth = 50
</script>

<template>
  <td :colspan="props.colspan" :class="bookingRowColor">
    <div class="flex flex-row items-center">
      <div style="width: 60px" class="m-1 text-center">
        <Spinner
          v-if="bookingRequestState.status === 'pending'"
          :width="spinnerWidth"
        />

        <!-- Icons -->
        <i-mdi:check-circle-outline
          v-if="bookingRequestState.status === 'booked'"
          class="icon"
        />
        <i-mdi:verified
          v-if="bookingRequestState.status === 'verified'"
          class="icon"
        />
        <i-mdi:block
          v-if="bookingRequestState.status === 'rejected'"
          class="icon"
        />
        <i-mdi:emoticon-dead-outline
          v-if="bookingRequestState.status === 'failed'"
          class="icon"
        />
      </div>
      <div class="flex-1">
        <template v-if="bookingRequestState.status === 'pending'">
          Booking appointment for
          {{
            bookingRequestState.selectedSlot.window_start.toFormat(
              'ccc, MMM d HH:mm'
            )
          }}...
        </template>
        <template v-else-if="bookingRequestState.status === 'booked'">
          Booking succeeded. Verifying appointment...
        </template>
        <template v-else-if="bookingRequestState.status === 'verified'">
          Appointment booked and verified.
          <span v-if="bookedAppointment" class="ml-2">
            <ClickToCopy
              :text-to-copy="
                bookedAppointment.appointment.display_terminal_reference
              "
              class="font-semibold"
            >
              #{{ bookedAppointment.appointment.display_terminal_reference }}
              <i-mdi:content-copy class="ml-1 align-middle mb-2px" />
            </ClickToCopy>
          </span>
          <template v-else> Loading appointment number... </template>
        </template>
        <template
          v-else-if="
            bookingRequestState.bookingRequest &&
            bookingRequestState.bookingRequest.error_class_name
          "
        >
          Booking {{ bookingRequestState.bookingRequest.status }} because:
          <span class="font-semibold">{{
            addSpacesToCamelCase(
              bookingRequestState.bookingRequest.error_class_name
            )
          }}</span>
          <div class="text-sm text-gray-900 mt-1 italic">
            "{{ bookingRequestState.bookingRequest.error_message }}"
          </div>
        </template>
      </div>
    </div>
  </td>
</template>

<style scoped lang="scss">
.icon {
  @apply align-middle text-3xl;
}
</style>
