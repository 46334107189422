<script lang="ts" setup>
import type { DateTime } from 'luxon'
import type { AppointmentTransactionGroup } from './types'
import { CapacityBucketAndCount, ShiftGroup } from './types'
import {
  groupContainersIntoTransactions,
  shiftGroupsToAppointedContainers,
} from './helpers'
import ShiftGroupHeader from './ShiftGroupHeader.vue'
import HourGroupHeader from './HourGroupHeader.vue'
import type { Container } from '~/models/containers'
import { useCapacitiesStore } from '~/stores/capacities'
import StickyHeaderVirtualScroller from '~/components/display/virtual_scrolling/StickyHeaderVirtualScroller.vue'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { shiftNameFromTime } from '~/logic/shifts'
const props = defineProps({
  containers: {
    type: Array as PropType<Container[]>,
    required: true,
  },
})
const capacitiesStore = useCapacitiesStore()
onMounted(capacitiesStore.loadBucketsIfNotLoaded)

const transactions = computed(() => {
  return groupContainersIntoTransactions(props.containers)
})

const getHeaderId = (row: AppointmentTransactionGroup) => {
  return row.shiftGroup
    ? `shift-${row.shiftGroup.shiftStart.toISO()}`
    : 'unknown'
}

const getSubHeaderId = (row: AppointmentTransactionGroup) => {
  return row.hourGroup ? `hour-${row.hourGroup.startTime.toISO()}` : 'unknown'
}

const shiftGroups = computed(() => {
  const containersByShiftAndHour = new Map() as Map<string, ShiftGroup>
  const bucketsLookup = new Map() as Map<string, CapacityBucketAndCount>
  transactions.value.forEach((transaction) => {
    const { empty_in, load_out } = transaction
    const bucketsThatApplyToTransaction = new Map<
      string,
      CapacityBucketAndCount
    >()
    let shiftStart: DateTime | undefined
    if (load_out) {
      if (load_out.scheduledShiftStart) {
        const capacityBuckets = capacitiesStore.getBucketsForTime(
          load_out.latest_appointment!.window_start,
          load_out.customer_id ?? null,
          load_out.tags
        )
        for (const capacityBucket of capacityBuckets) {
          const bucketWithCount =
            bucketsLookup.get(capacityBucket.key) ||
            new CapacityBucketAndCount(capacityBucket)
          if (load_out) {
            // Capacity is only for load outs
            bucketWithCount.numScheduledAppointments += 1
          }
          bucketsLookup.set(capacityBucket.key, bucketWithCount)
          bucketsThatApplyToTransaction.set(capacityBucket.key, bucketWithCount)
        }
        shiftStart = load_out.scheduledShiftStart
      } else {
        console.warn('Container without shift', transaction)
      }
    } else if (empty_in) {
      // Single empty in case
      if (empty_in.scheduledShiftStart) {
        shiftStart = empty_in.scheduledShiftStart
      } else {
        console.warn('Container without shift', transaction)
      }
    }
    if (shiftStart) {
      const shiftName = shiftNameFromTime(shiftStart)
      const shiftGroup =
        containersByShiftAndHour.get(shiftName) || new ShiftGroup(shiftStart)
      containersByShiftAndHour.set(shiftName, shiftGroup)
      shiftGroup.addTransaction(transaction, [
        ...bucketsThatApplyToTransaction.values(),
      ])
    }
  })

  return shiftGroupsToAppointedContainers([
    ...containersByShiftAndHour.values(),
  ]).map((row, index) => ({
    ...row,
    id: `shiftGroup-${index}`,
  }))
})
</script>

<template>
  <StickyHeaderVirtualScroller
    :key="shiftGroups.length"
    :items="shiftGroups"
    size-field="rowHeight"
    key-field="key"
    page-mode
    :buffer="600"
    class="h-full"
    :row-height="88"
    :header-height="25"
    :sub-header-height="25"
    :get-header-id="getHeaderId"
    :get-sub-header-id="getSubHeaderId"
    :get-row-height="(shiftGroup) => shiftGroup.rowHeight"
  >
    <template #default="{ item }">
      <ContainerRow
        ref="rowRef"
        :container="item.primaryContainer"
        :style="{ height: `${item.rowHeight}px` }"
      />
    </template>

    <template #header="{ item }">
      <ShiftGroupHeader
        v-if="item.isFirstRowInShift && item.shiftGroup"
        :shift-group="item.shiftGroup"
      />
    </template>

    <template #subheader="{ item }">
      <HourGroupHeader
        v-if="item.isFirstRowInHour && item.hourGroup"
        :hour-group="item.hourGroup"
      />
    </template>
  </StickyHeaderVirtualScroller>
</template>
