<script lang="ts" setup>
import type { AppointmentTransaction } from '~/models/groupedAppointments'
import CheckInAppointmentButton from '~/components/container_row/CheckInAppointmentButton.vue'

const props = defineProps<{
  transaction: AppointmentTransaction | null
}>()

// Extract the appointment from the transaction
const appointment = computed(
  () => props.transaction?.outbound_appointment?.appointment
)

const canCheckIn = computed(
  () => props.transaction?.primary_appointment.appointment.is_import_out
)
</script>

<template>
  <CheckInAppointmentButton
    v-if="canCheckIn && appointment"
    :appointment="appointment"
  />
</template>
