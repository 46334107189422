<script lang="ts" setup>
import { computed, toRef } from 'vue'
import StickyHeaderVirtualScroller from '~/components/display/virtual_scrolling/StickyHeaderVirtualScroller.vue'
import type { Container } from '~/models/containers'
import type { VesselETA } from '~/models/vesselETA'
import { useVesselETAsStore } from '~/stores/vesselETAs'

const props = defineProps<{ containers: Container[] }>()
const vesselETAStore = useVesselETAsStore()
const containers = toRef(props, 'containers')

const vesselETALookup = computed(() => {
  const lookup = new Map<string, VesselETA | null>()
  containers.value.forEach((container) => {
    if (container.matched_vessel) {
      lookup.set(
        String(container.matched_vessel.id),
        vesselETAStore.getVesselETA(
          container.matched_vessel.id,
          container.terminal
        )
      )
    }
  })
  return lookup
})

class ContainerWithETA {
  id: string
  container: Container
  eta: VesselETA | null

  constructor(container: Container, eta: VesselETA | null) {
    this.container = container
    this.eta = eta
    this.id = container.container_number
  }
}

const containersWithETA = computed(() => {
  const countsByETA = new Map<string, number>()
  const mappedContainers: ContainerWithETA[] = []

  containers.value.forEach((container) => {
    const eta = container.matched_vessel
      ? (vesselETALookup.value.get(String(container.matched_vessel.id)) ?? null)
      : null
    const etaText = eta?.eta.toISODate() || 'No ETA'
    const existingCount = countsByETA.get(etaText) || 0
    countsByETA.set(etaText, existingCount + 1)
    mappedContainers.push(new ContainerWithETA(container, eta))
  })

  // Sorting the containers
  mappedContainers.sort((a, b) => {
    if (!a.eta && !b.eta) return 0
    if (!a.eta) return 1
    if (!b.eta) return -1
    return a.eta.eta.toMillis() - b.eta.eta.toMillis()
  })

  return mappedContainers
})

const getHeaderId = (container: ContainerWithETA) => {
  return container.eta?.eta.toISODate() || 'No ETA'
}

const countContainers = computed(() => {
  const counts: Record<string, number> = {}
  containersWithETA.value.forEach((item) => {
    const date = item.eta?.eta.toISODate() || 'No ETA'
    counts[date] = (counts[date] || 0) + 1
  })
  return counts
})
</script>

<template>
  <StickyHeaderVirtualScroller
    :items="containersWithETA"
    size-field="rowHeight"
    key-field="key"
    page-mode
    :buffer="600"
    class="h-full"
    :header-height="50"
    :get-header-id="getHeaderId"
    :get-row-height="(containerWithETA) => containerWithETA.container.rowHeight"
  >
    <template #default="{ item }">
      <ContainerRow :container="item.container" />
    </template>

    <template #header="{ item }">
      <ETAGroupHeader
        :eta="item.eta?.eta?.toISODate() || null"
        :container-count="
          countContainers[item.eta?.eta?.toISODate() || 'No ETA']
        "
      />
    </template>
  </StickyHeaderVirtualScroller>
</template>
