<script setup lang="ts">
import { ref } from 'vue'
import { ElMessage, type FormInstance } from 'element-plus'
import type { Appointment } from '~/models/appointments'
import type {
  AppointmentSlot,
  BaseAppointmentSlot,
} from '~/models/appointmentSlot'
import {
  TERMINALS_REQUIRING_MASTER_BL_FOR_UAS,
  TERMINALS_USING_OWN_CHASSIS_DESIGNATION,
  getTerminalLabel,
} from '~/constants'
import LicensePlateSelector from '~/components/inputs/LicensePlateSelector.vue'
import BaseDialog from '~/components/dialogs/BaseDialog.vue'
import type { TerminalName } from '~/services/apiClient'
import { useBookAppointment } from '~~/compositions/booking'
import type { ImportStatusBase } from '~~/models/importStatuses'
import { useUserStore } from '~/stores/user'
import { useLicensePlateRules } from '~/compositions/useLicensePlateRules'
import type { MutationRequestState } from '~/stores/mutationRequests'

const props = defineProps<{
  appointmentSlot: BaseAppointmentSlot
  importStatus: ImportStatusBase | undefined
  containerNumber: string
  terminal: TerminalName
  existingAppointment: Appointment | undefined
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'bookingBegun', event: MutationRequestState): void
}>()

const { startBooking } = useBookAppointment()

const appointmentSlotWindowStart = computed(() => {
  return props.appointmentSlot?.window_start
})
const existingAppointmentRef = toRef(
  props.existingAppointment
) as Ref<Appointment | null>
const terminal = toRef(props.terminal)
const { usesLicensePlate, requiresLicensePlate } = useLicensePlateRules({
  terminal,
  appointmentWindowStart: appointmentSlotWindowStart,
  forEmptyBooking: false,
  existingAppointment: existingAppointmentRef,
})
const userStore = useUserStore()
const usesChassisSelector = computed(() => {
  return TERMINALS_USING_OWN_CHASSIS_DESIGNATION.has(props.terminal)
})
const startingBooking = ref<boolean>(false)
const ownChassis = toRef(userStore, 'useOwnChassis')
const terminalRequiresMasterBL = computed(() => {
  return TERMINALS_REQUIRING_MASTER_BL_FOR_UAS.has(props.terminal)
})
const formDetailsToComplete = computed(() => {
  if (usesChassisSelector.value) {
    return true
  } else if (usesLicensePlate.value || requiresLicensePlate.value) {
    return true
  }
  return false
})
const formRules = reactive({
  masterBL: [
    {
      required: terminalRequiresMasterBL.value,
      message: 'Required',
      trigger: 'change',
    },
  ],
  licensePlate: [
    {
      required: requiresLicensePlate.value,
      message: 'Required',
      trigger: 'change',
    },
  ],
})
const formRef = ref<FormInstance>()
const form = reactive({
  masterBL: '',
  licensePlate: '',
})

const rescheduling = computed(() => !!props.existingAppointment)
const verb = computed(() => (rescheduling.value ? 'Reschedule' : 'Book'))
const noun = computed(() => (rescheduling.value ? 'Reschedule' : 'Booking'))
const title = computed(() => `${verb.value} Appointment`)

const onClickBook = () => {
  if (!formRef.value) {
    throw new Error('Form ref not found')
  }
  formRef.value.validate((valid) => {
    if (!valid) {
      ElMessage.error('Please fill in the required fields')
    } else {
      if (props.existingAppointment) {
        let errorMsg = null as null | string
        if (props.existingAppointment.terminal !== props.terminal) {
          errorMsg =
            'Existing appointment terminal does not match import status terminal'
        }
        if (errorMsg) {
          ElMessage.warning(errorMsg)
          throw new Error(errorMsg)
        }
      }
      startingBooking.value = true
      startBooking({
        // TODO: Fix typing so we don't need this case
        slot: props.appointmentSlot as AppointmentSlot,
        containerNumber: props.containerNumber,
        terminal: props.terminal,
        masterBL: form.masterBL || undefined,
        licensePlate: form.licensePlate || undefined,
        ownChassis: usesChassisSelector.value ? ownChassis.value : undefined,
        existingAppointmentId: props.existingAppointment?.id,
      })
        .then((requestState) => {
          emit('bookingBegun', requestState)
        })
        .finally(() => {
          startingBooking.value = false
        })
    }
  })
}

watch(
  existingAppointmentRef,
  () => {
    if (
      existingAppointmentRef.value &&
      usesLicensePlate &&
      existingAppointmentRef.value.truck_license_plate_number
    ) {
      form.licensePlate =
        existingAppointmentRef.value.truck_license_plate_number
    }
    if (
      existingAppointmentRef.value &&
      usesChassisSelector &&
      existingAppointmentRef.value.extra_data &&
      'own_chassis' in existingAppointmentRef.value.extra_data &&
      existingAppointmentRef.value.extra_data.own_chassis !== null
    ) {
      ownChassis.value = existingAppointmentRef.value.extra_data
        .own_chassis as boolean
    }
  },
  { immediate: true }
)
</script>

<template>
  <BaseDialog
    :model-value="true"
    :title="title"
    :min-width="500"
    center
    :proportional-width="0.1"
    :close-on-click-modal="true"
    @close="emit('close')"
  >
    <template #header>
      <div class="text-center text-xl">
        <i-mdi:calendar-sync-outline
          v-if="rescheduling"
          class="align-text-bottom -mb-1px"
        />
        <i-mdi:calendar-plus-outline v-else class="align-text-bottom -mb-1px" />
        {{ title }}
      </div>
    </template>
    <div class="booking-form">
      <p>
        <strong>Container: </strong>
        {{ props.containerNumber }}
      </p>
      <p>
        <strong>Terminal: </strong>
        {{ getTerminalLabel(terminal) }}
      </p>
      <div class="form-group">
        <p>
          <strong>Date:</strong>
          {{ props.appointmentSlot.window_start.toFormat('cccc, LLL dd') }}
        </p>
        <p>
          <strong>Time:</strong>
          {{ props.appointmentSlot.window_start.toFormat('HH:mm') }} -
          {{ props.appointmentSlot.window_end?.toFormat('HH:mm') }}
        </p>
        <!-- <p><strong>Location:</strong> {{ props.bookingEvent.slot.terminal }}</p> -->
      </div>
      <el-form ref="formRef" :model="form" :rules="formRules" size="small">
        <div v-if="formDetailsToComplete" class="form-group">
          <p>Please check the details and fill in the required data:</p>
          <el-form-item
            v-if="requiresLicensePlate || usesLicensePlate"
            prop="licensePlate"
            label="License Plate:"
            :class="{ 'max-w-380px': 'auto' }"
          >
            <LicensePlateSelector
              v-if="!props.existingAppointment"
              v-model="form.licensePlate"
              :terminal="props.terminal"
              :disabled="Boolean(props.existingAppointment)"
            />
            <span v-else>{{ form.licensePlate }}</span>
          </el-form-item>
          <el-form-item
            v-if="terminalRequiresMasterBL"
            prop="masterBL"
            label="Pin #:"
            :class="{ 'max-w-380px': 'auto' }"
          >
            <el-input v-model="form.masterBL" placeholder="Enter Pin #" />
          </el-form-item>
          <el-form-item
            v-if="usesChassisSelector"
            label="Chassis:"
            prop="ownChassis"
          >
            <el-switch
              v-model="ownChassis"
              inline-prompt
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Own"
              inactive-text="Pool"
              :disabled="Boolean(props.existingAppointment)"
            />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <template #action-button>
      <el-button
        type="success"
        size="large"
        :loading="startingBooking"
        @click="onClickBook"
      >
        Confirm {{ noun }}
      </el-button>
    </template>
  </BaseDialog>
</template>

<style scoped>
.booking-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

label {
  font-weight: bold;
}
</style>
