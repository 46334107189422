<script lang="ts" setup>
import type { HourGroup } from './types'
import AppointmentCounts from './AppointmentCounts.vue'
import { appointmentCountDescription } from './utils'
import CapacityWarningBadge from './CapacityWarningBadge.vue'

const props = defineProps<{ hourGroup: HourGroup }>()

function hourToMilitaryTime(hour: number, minute = 0): string {
  return (
    hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
  )
}
const tooltip = computed(() => {
  return `${appointmentCountDescription(
    props.hourGroup
  )} scheduled from ${hourToMilitaryTime(
    props.hourGroup.hour
  )} to ${hourToMilitaryTime(props.hourGroup.hour, 59)}`
})
</script>

<template>
  <BaseAppointmentHeader :tooltip="tooltip" :is-sub-header="true">
    <template #left>
      {{ hourToMilitaryTime(hourGroup.hour) }}
    </template>
    <template #right>
      <CapacityWarningBadge
        v-for="capacityBucket in hourGroup.overCapacityBuckets"
        :key="capacityBucket.bucket.key"
        :capacity-bucket="capacityBucket"
        class="ml-2"
      />
      <AppointmentCounts :group="hourGroup" />
    </template>
  </BaseAppointmentHeader>
</template>
