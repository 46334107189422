<script lang="ts" setup>
import { computed, toRef } from 'vue'
import type { Container } from '~/models/containers'
import StickyHeaderVirtualScroller from '~/components/display/virtual_scrolling/StickyHeaderVirtualScroller.vue'

const props = defineProps<{
  containers: Container[]
}>()

const containers = toRef(props, 'containers')

class ContainerWithID {
  id: string
  container: Container
  isFirstInGroup: boolean

  constructor(container: Container, isFirstInGroup: boolean) {
    this.container = container
    this.id = container.container_number
    this.isFirstInGroup = isFirstInGroup
  }
}

const containersWithID = computed<ContainerWithID[]>(() => {
  return containers.value.map((container, index) => ({
    id: container.container_number,
    container,
    isFirstInGroup: index === 0,
  }))
})

const getHeaderId = () => 'No Header'
</script>

<template>
  <StickyHeaderVirtualScroller
    :items="containersWithID"
    size-field="rowHeight"
    key-field="key"
    page-mode
    :buffer="600"
    class="h-full"
    :row-height="88"
    :header-height="0"
    :get-header-id="getHeaderId"
    :get-row-height="(containerWithID) => containerWithID.container.rowHeight"
  >
    <template #default="{ item }">
      <ContainerRow
        :container="item.container"
        :class="{ 'border-t-0': item.isFirstInGroup }"
      />
    </template>
  </StickyHeaderVirtualScroller>
</template>
