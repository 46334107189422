import { ElNotification } from 'element-plus'
import { defineStore } from 'pinia'
import { DateTime } from 'luxon'
import type { AxiosResponse } from 'axios'
import { useUserStore } from './user'
import { useCustomerStore } from './customers'
import type { Appointment } from '~/models/appointments'
import { Container } from '~/models/containers'
import type {
  AppointmentsCheckedNotification,
  GETContainer,
  ImportStatusCheckedNotification,
  POSTContainerWatch,
  POSTUploadContainersResponse,
  SimpleUser,
  TerminalName,
} from '~/services/apiClient'
import { ContainerWatchState, ContainersApi } from '~/services/apiClient'
import type { ImportStatus } from '~/models/importStatus'
import type { LoadContainerOptions } from '~/services/apiHelpers'
import { getSortFunctionForState, loadContainers } from '~/services/apiHelpers'
import type { ContainerFilters } from '~/compositions/containerFilters'

export const useContainerStore = defineStore('containers', {
  state: () => {
    return {
      loading: false,
      preLoading: false,
      containers: [] as Container[],
      containersSortFunction: getSortFunctionForState(undefined),
      containerLookup: new Map() as Map<string, Container>,
      containerLookupByTerminal: new Map() as Map<TerminalName, Container[]>,
      // For live updating "last_checked" fields
      importStatusLookup: new Map() as Map<number, ImportStatus>,
      appointmentsLookup: new Map() as Map<number, Appointment>,
      loadContainersAbortController: null as AbortController | null,
      loadStateCountsAbortController: null as AbortController | null,
      loadTerminalCountsAbortController: null as AbortController | null,
      focusedContainer: null as Container | null,
      // Keys are container numbers
      selectedContainers: new Set<string>(),
      // This key is used to force re-rendering of checkboxes whenever the selection
      // changes
      selectionKey: 0,
      searchedButUnwatchedContainers: [] as string[],
      lastUsedFilters: undefined as LoadContainerOptions | undefined,

      executingWatchContainers: false,
      statusCounts: new Map() as Map<ContainerWatchState, number | null>,
      countsByTerminal: new Map() as Map<TerminalName, number>,
    }
  },
  actions: {
    clearAllData() {
      this.containers = []
      this.containerLookup = new Map()
      this.importStatusLookup = new Map()
      this.appointmentsLookup = new Map()
      this.containerLookupByTerminal = new Map()
      this.focusedContainer = null
    },
    cancelExistingRequests(): AbortController {
      if (this.loadContainersAbortController) {
        this.loadContainersAbortController.abort()
      }
      const abortController = new AbortController()
      this.loadContainersAbortController = abortController
      return abortController
    },
    load(originalOptions: LoadContainerOptions, followOnLoading = false) {
      const options = { ...originalOptions }
      const newAbortController = this.cancelExistingRequests()
      if (!followOnLoading) {
        this.preLoading = true
        this.clearAllData()
      }
      this.loading = true
      this.lastUsedFilters = options
      const pageSize = options.pageSize
      if (options.preloadSize !== undefined) {
        options.pageSize = options.preloadSize
      }
      if (options.states) {
        this.containersSortFunction = getSortFunctionForState(options.states[0])
      } else {
        this.containersSortFunction = getSortFunctionForState(undefined)
      }
      loadContainers({
        ...options,
        abortController: this.loadContainersAbortController || undefined,
      })
        .then((resp) => {
          this.addContainers(resp.data.containers)
          this.searchedButUnwatchedContainers = resp.data.unwatched_containers
          if (!followOnLoading) {
            // If this is an initial load, clear our container selection
            this.selectedContainers = new Set()
          }
          this.loadContainersAbortController = null
          if (options.preloadSize !== undefined) {
            // Completed pre-load case
            this.preLoading = false
            if (resp.data.containers.length === options.preloadSize) {
              options.pageSize = pageSize - options.preloadSize
              options.offset =
                options.preloadSize +
                (originalOptions.page - 1) * originalOptions.pageSize
              options.preloadSize = undefined
              this.load(options, true)
            } else {
              this.loading = false
              this.preLoading = false
            }
          } else {
            // Completed full load case
            this.loading = false
            this.preLoading = false
          }
        })
        .catch((err) => {
          if (err.name !== 'CanceledError') {
            // CancelledError means that another request was started, so we don't
            // want to indicate that we are no longer loading
            this.loading = false
            this.preLoading = false
            throw err
          }
        })
        .finally(() => {
          if (this.loadContainersAbortController === newAbortController) {
            this.loadContainersAbortController = null
          }
        })
    },
    parseContainers(rawContainers: GETContainer[]) {
      const userStore = useUserStore()
      const containers = rawContainers.map(
        (rawContainer) => new Container(rawContainer, userStore.demo_mode)
      )
      return containers
    },
    addContainers(rawContainers: GETContainer[]) {
      const containers = this.parseContainers(rawContainers)
      this.addParsedContainers(containers)
    },
    addParsedContainers(containers: Container[]) {
      for (const container of containers) {
        // Update internal object lookups
        for (const importStatus of container.import_statuses) {
          this.importStatusLookup.set(importStatus.cycle_id, importStatus)
        }
        if (container.latest_appointment) {
          this.appointmentsLookup.set(
            container.latest_appointment.id,
            container.latest_appointment
          )
        }
        // Update container
        const existingContainer = this.containerLookup.get(container.number)
        if (existingContainer) {
          container.incrementFrontendVersion(
            existingContainer.frontendVersion + 1
          )
        }
        this.containerLookup.set(container.number, container)
        if (container.terminal) {
          if (!this.containerLookupByTerminal.has(container.terminal)) {
            this.containerLookupByTerminal.set(container.terminal, [])
          }
          this.containerLookupByTerminal
            .get(container.terminal)!
            .push(container)
        }
      }
      this.refreshContainersView()
    },
    removeContainers(containerNumbers: string[]) {
      const api = new ContainersApi()
      api
        .stopWatchingContainersContainersUnwatchPost(containerNumbers)
        .then(() => {
          ElNotification.success(
            `Removed ${containerNumbers.length} containers`
          )
          for (const containerNumber of containerNumbers) {
            this.containerLookup.delete(containerNumber)
          }
          this.refreshContainersView()
        })
    },
    refreshContainersView() {
      this.containers = [...this.containerLookup.values()]
    },
    updateContainer(rawContainer: GETContainer) {
      const container = this.parseContainers([rawContainer])[0]
      // Add to our store if this matches our filters OR the container is already in our
      // store
      if (
        (this.lastUsedFilters &&
          container.matchesFilters(this.lastUsedFilters)) ||
        this.containerLookup.has(container.number)
      ) {
        // eslint-disable-next-line no-console
        console.log(
          `Updating container ${container.number} that matches ` +
            `filters ${JSON.stringify(this.lastUsedFilters)}`
        )
        this.addParsedContainers([container])
      }
    },
    updateImportStatusLastObservedTimes(
      notification: ImportStatusCheckedNotification
    ) {
      for (const [rawImportStatusCycleID, rawObserved] of Object.entries(
        notification.last_checked_by_import_status_ids
      )) {
        const importStatusCycleID = parseInt(rawImportStatusCycleID)
        const observed = DateTime.fromISO(rawObserved)
        const loadedImportStatus =
          this.importStatusLookup.get(importStatusCycleID)
        if (loadedImportStatus) {
          loadedImportStatus.last_observed = observed
        }
      }
    },
    updateAppointmentLastObservedTimes(
      notification: AppointmentsCheckedNotification
    ) {
      for (const [rawAppointmentID, rawObserved] of Object.entries(
        notification.last_checked_by_appointment_ids
      )) {
        const appointmentID = parseInt(rawAppointmentID)
        const observed = DateTime.fromISO(rawObserved)
        const loadedAppointment = this.appointmentsLookup.get(appointmentID)
        if (loadedAppointment) {
          // eslint-disable-next-line no-console
          console.log(
            `Updating appointment ${appointmentID} for ` +
              `${loadedAppointment.container_number} last observed ${observed}`
          )
          loadedAppointment.last_observed = observed
        }
      }
    },
    loadStateCounts({
      vessels,
      tags,
      terminal,
      customer_id,
      container_numbers,
      auto_book_on,
      auto_book_off,
      container_types,
      shipping_lines,
      assigned_user_ids,
      updated_in_last_minutes,
    }: ContainerFilters) {
      if (this.loadStateCountsAbortController) {
        this.loadStateCountsAbortController.abort()
      }
      const abortController = new AbortController()
      this.loadStateCountsAbortController = abortController
      const api = new ContainersApi()
      api
        .getContainerCountsByStateContainersStateCountsGet(
          terminal,
          vessels,
          customer_id,
          auto_book_on,
          auto_book_off,
          container_types,
          shipping_lines,
          updated_in_last_minutes,
          tags,
          container_numbers,
          assigned_user_ids,
          { signal: this.loadStateCountsAbortController.signal }
        )
        .then((resp) => {
          this.statusCounts = new Map(resp.data.map((x) => [x.state, x.count]))
        })
        .finally(() => {
          if (this.loadStateCountsAbortController === abortController) {
            this.loadStateCountsAbortController = null
          }
        })
    },
    loadByTerminalCounts({
      vessels,
      tags,
      states,
      customer_id,
      container_numbers,
      container_types,
      shipping_lines,
      auto_book_on,
      auto_book_off,
      assigned_user_ids,
      updated_in_last_minutes,
    }: ContainerFilters) {
      if (this.loadTerminalCountsAbortController) {
        this.loadTerminalCountsAbortController.abort()
      }
      const abortController = new AbortController()
      this.loadTerminalCountsAbortController = abortController
      const api = new ContainersApi()
      api
        .getContainerCountsByTerminalContainersTerminalCountsGet(
          states,
          undefined,
          undefined,
          vessels,
          customer_id,
          auto_book_on,
          auto_book_off,
          container_types,
          shipping_lines,
          updated_in_last_minutes,
          tags,
          container_numbers,
          assigned_user_ids,
          { signal: this.loadTerminalCountsAbortController.signal }
        )
        .then((resp) => {
          this.countsByTerminal = new Map(
            resp.data.map((x) => [x.terminal, x.count])
          )
        })
        .finally(() => {
          if (this.loadTerminalCountsAbortController === abortController) {
            this.loadTerminalCountsAbortController = null
          }
        })
    },
    watchUnwatchedContainers(
      assignedUsers: SimpleUser[],
      customerID: number | undefined,
      tags: string[]
    ): Promise<AxiosResponse<POSTUploadContainersResponse>> {
      const newWatches = this.searchedButUnwatchedContainers.map(
        (containerNumber) => {
          return {
            container_number: containerNumber,
            customer_id: customerID,
            tags,
          }
        }
      )
      return this.watchContainers(newWatches, assignedUsers, customerID).then(
        (response) => {
          this.searchedButUnwatchedContainers = response.data.errors.map(
            (error) => error.container_number
          )
          return response
        }
      )
    },
    watchContainers(
      watches: POSTContainerWatch[],
      assignedUsers: SimpleUser[],
      customerID: number | undefined,
      cycleState: ContainerWatchState | undefined = undefined
    ): Promise<AxiosResponse<POSTUploadContainersResponse>> {
      this.executingWatchContainers = true
      const assignedUserIDs = assignedUsers.map((u) => u.id)
      const api = new ContainersApi()
      const watchPromise = api.watchContainersContainersWatchPost(
        watches,
        assignedUserIDs
      )
      watchPromise
        .then((response) => {
          if (response.data.container_numbers.length > 0) {
            const userStore = useUserStore()
            const customerStore = useCustomerStore()
            for (const watch of watches) {
              if (watch.container_number in response.data.container_numbers) {
                this.addContainers([
                  {
                    container_number: watch.container_number,
                    cycle_state: cycleState || ContainerWatchState.Unknown,
                    import_statuses: [],
                    auto_book_ignoring_capacity: false,
                    auto_book_on: false,
                    assigned_users: assignedUsers,
                    added_by_user: userStore.loggedInUser || undefined,
                    customer:
                      customerID !== undefined
                        ? customerStore.getByCustomerID(customerID)
                        : undefined,
                    other_appointment_counts: [],
                    tags: [],
                    watched_time: DateTime.now().toISO(),
                  },
                ])
              }
            }
          }
        })
        .finally(() => {
          this.executingWatchContainers = false
        })
      return watchPromise
    },
    // Selections
    clearContainerSelection() {
      let hasSelectedContainers = false
      for (const container of this.containers) {
        if (this.selectedContainers.has(container.number)) {
          hasSelectedContainers = true
        }
        this.selectedContainers.delete(container.number)
      }
      // Only trigger a re-render of checkboxes if there were any selected checkboxes
      // to clear
      if (hasSelectedContainers) {
        this.selectionKey += 1
      }
    },
    selectAllContainers() {
      for (const container of this.containers) {
        this.selectedContainers.add(container.number)
      }
      this.selectionKey += 1
    },
    toggleContainerSelection(containerNumber: string) {
      if (this.selectedContainers.has(containerNumber)) {
        this.selectedContainers.delete(containerNumber)
      } else {
        this.selectedContainers.add(containerNumber)
      }
      this.selectionKey += 1
    },
  },
  getters: {
    numUnwatchedContainers(): number {
      return this.searchedButUnwatchedContainers.length
    },
    isContainerSelected() {
      return (container: string): boolean => {
        return this.selectedContainers.has(container)
      }
    },
    selectedContainerNumbers(): string[] {
      return [...this.selectedContainers]
    },
    selectedContainerObjects(): Container[] {
      const containers = []
      for (const containerNumber of this.selectedContainerNumbers) {
        const container = this.containerLookup.get(containerNumber)
        if (container) {
          containers.push(container)
        }
      }
      return containers
    },
    assignedUsersForSelectedContainers(): SimpleUser[] {
      const assignedUsers = new Map()
      for (const container of this.selectedContainerObjects) {
        for (const user of container.assigned_users) {
          assignedUsers.set(user.id, user)
        }
      }
      return [...assignedUsers.values()]
    },
    selectedContainerAppointments(): Appointment[] {
      return this.selectedContainerObjects
        .filter(
          (c) =>
            c.cycle_state === ContainerWatchState.ImportApptBooked &&
            c.latest_appointment
        )
        .map((c) => c.latest_appointment!)
    },
    numAppointedContainersForSelection(): number {
      return this.selectedContainerAppointments.length
    },
  },
})
